import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField, Button, Grid, Typography, Box } from "@mui/material";
import Header2 from "../component/Header2";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDriversLicense,
  faEnvelope,
  faHome,
  faLaptop,
  faLocationArrow,
  // faLocationDot,
  faPhone,
  faRocket,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { faSignalMessenger } from "@fortawesome/free-brands-svg-icons";
import "./about.css";

const Contact = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    phone: "",
    message: "",
  });

  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Basic form validation
    if (
      !formData.user_name ||
      !formData.user_email ||
      !formData.phone ||
      !formData.message
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    emailjs
      .sendForm(
        "service_avam5ds",
        "template_2ehyu0t",
        form.current,
        "vB17qrUsLZXvgSYHG"
      )
      .then(
        (result) => {
          // console.log(result.text);
          toast.success("ThankYou");
          window.location.reload(); // Reload the page on success
        },
        (error) => {
          // console.log(error.text);
          toast.error("Failed to send message. Please try again later.");
        }
      );
  };
  const card = [
    {
      icon: faThumbsUp,
      head: "One Stop Solution",
      text: "sstcloud provides end to end solutions from Cloud Servers, Bare-Metal Servers, PaaS, Backup & Disaster Recovery, Enterprise Email and Co-Location all under one roof",
    },
    {
      icon: faLocationArrow,
      head: "Reach",
      text: "With datacenter presence in 5 locations across 3 continents we provide a wide range of coverage to cater to all your requirements.",
    },
    {
      icon: faPhone,
      head: "Great Support",
      text: "We empower our customers with 24x7 Support via call, chat, ticketing system and email.",
    },
    {
      icon: faRocket,
      head: "Performance",
      text: "With 99.99% uptime and our servers updated every couple years, we provide superior performance.",
    },
    {
      icon: faDriversLicense,
      head: "Highly Recomended",
      text: "Recognized by the Govt. of India as one of the top 100 SME in India.",
    },
    {
      icon: faSignalMessenger,
      head: "Positive Reviews",
      text: "With over 5000+ clients we have an approval rating of 97%.",
    },
  ];

  return (
    <>
      <ToastContainer />
      {isMobile ? <Header2 /> : <Header />}
      <div className="container marginbotm my-5">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <img alt="img" src="./img/abt1.png" style={{ width: "100%" }} />
          </div>
          <div className="col-md-8 col-sm-12 px-3">
            <p
              style={{
                fontSize: "25px",
                lineHeight: "30px",
                fontWeight: "600",
                color: "rgb(230,57,63)",
              }}
            >
              WELCOME TO SSTCLOUD NETWORKS
            </p>
            <p style={{ fontSize: "16px", lineHeight: "24px" }}>
              We offer Robust Infrastructure in 5 locations worldwide. We
              embrace the digital journey of Enterprise and SMB Clients with our
              unique approach and innovative products serving 20000+ Customers.
            </p>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <FontAwesomeIcon
                  icon={faRocket}
                  style={{
                    fontSize: "23px",
                    lineHeight: "23px",
                    padding: "10px 0",
                    color: "rgb(230,57,63)",
                  }}
                />
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontWeight: "600",
                  }}
                >
                  3000+
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    lineHeight: "20px",
                  }}
                >
                  Nodes deployed
                </p>
              </div>
              <div className="col-md-4 col-sm-12">
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  style={{
                    fontSize: "23px",
                    lineHeight: "23px",
                    padding: "10px 0",
                    color: "rgb(230,57,63)",
                  }}
                />
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontWeight: "600",
                  }}
                >
                  1 Million+
                </p>
                <p>Problems we solved</p>
              </div>
              <div className="col-md-4 col-sm-12">
                <FontAwesomeIcon
                  icon={faLaptop}
                  style={{
                    fontSize: "23px",
                    lineHeight: "23px",
                    padding: "10px 0",
                    color: "rgb(230,57,63)",
                  }}
                />
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontWeight: "600",
                  }}
                >
                  80 GBPS
                </p>
                <p>Network Capacity</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p style={{ fontSize: "15px", lineHeight: "24px" }}>
              sstcloud Networks Pvt. Ltd. has been in service since 2006
              providing cloud hosting, cloud virtual servers, bare-metal
              dedicated servers, platform-as-a-service cloud and colocation
              services. We provide a reliable infrastructure for your websites
              with a 100% network uptime and power uptime guarantee. We operate
              out of 19 worldwide locations in 3 continents in 10 countries.
            </p>
          </div>
        </div>
      </div>
      <div className="about1">
        <h2 style={{ color: "rgb(230,57,63)" }}>
          SOME FEATURES THAT MAKE US UNIQUE
        </h2>
        <p>
          We serve customers from 110+ countries worldwide across the Startup
          Ecosystem, Developers, Small businesses and enterprises who are
          transforming from traditional workplace to a digital enterprise.
        </p>
      </div>
      <div className="container">
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-md-4 col-sm-12 p-2">
                <div className="p-5 abtmap">
                  <h1>
                    <FontAwesomeIcon icon={e.icon} className="abtfont" />{" "}
                    {e.head}
                  </h1>
                  <p>{e.text}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container my-5">
        <Box mt={4}>
          <Typography
            color="rgb(230,57,63)"
            variant="h4"
            align="center"
            gutterBottom
          >
            Contact Us
          </Typography>
          <form ref={form} onSubmit={sendEmail}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "rgb(230,57,63)",
                    fontWeight: "600",
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
      <div className="container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.851477722317!2d77.32353657549095!3d28.36333957581289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd8a3acaef43%3A0xff5d5fabf55d2e19!2sdevFox%20Technology!5e0!3m2!1sen!2sin!4v1712918495529!5m2!1sen!2sin"
          width="100%"
          height="auto"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps"
        ></iframe>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-4 col-sm-12 p-3">
            <FontAwesomeIcon
              icon={faHome}
              style={{ color: "rgb(230, 57, 63)", fontSize: "20px" }}
            />
            <p className="abthomeh">Registered Office</p>
            <p className="abthomep"></p>
            <p className="abthomeh">Working Address - Delhi [NCR] </p>
            <p className="abthomep">
              Sco-27 second floor, near Mother dairy, Huda Market, Sector 7a,
              Block E, Sector 7, Faridabad, Haryana 121006
            </p>
          </div>
          <div className="col-md-4 col-sm-12 p-3">
            <FontAwesomeIcon
              icon={faPhone}
              style={{ color: "rgb(230, 57, 63)", fontSize: "20px" }}
            />
            <p className="abthomeh">+91 90159 37199</p>
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ color: "rgb(230, 57, 63)", fontSize: "20px" }}
            />
            <p className="abthomeh">sales@sstcloud.in</p>
            <p className="abthomep">Send us your query anytime!</p>
          </div>
          <div className="col-md-4 col-sm-12 p-3">
            <FontAwesomeIcon
              icon={faDriversLicense}
              style={{ color: "rgb(230, 57, 63)", fontSize: "20px" }}
            />
            <p className="abthomeh">Company Legal Details</p>
            <p className="abthomep">CIN : U30007PN2010PTC137171</p>
            <p className="abthomep">PAN : AABCL8707F</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
