// import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import GPU from "./pages/GPU";
import SharedHosting from "./pages/hosting/SharedHosting";
import LinusHosting from "./pages/hosting/linuxshared";
import WindowsHostingShared from "./pages/hosting/WindowsShared";
import ResellerLinux from "./pages/hosting/ResellerLinux";
import ResellerWindow from "./pages/hosting/ResellerWindows";
import CloudVPS from "./pages/VPS/Cloudvps";
import LinuxManagedVPS from "./pages/VPS/LinuxManaged";
import WindowManaged from "./pages/VPS/Windowmanaged";
import LinuxSelfManagedVPS from "./pages/VPS/LinuxSelfManaged";
import WindowSelfManagedVPS from "./pages/VPS/WindowSelfManaged";
import Dedicated from "./pages/dedicated/Dedicated";
// import AsiaDedi from "./pages/dedicated/Asiadedicated";
import UsePolicy from "./pages/privicy/UsePolicy";
import Terms from "./pages/privicy/Terms";
import PrivicyPolicy from "./pages/privicy/PrivicyPolicy";
import SSL from "./pages/security/SSL";
import ContactForm from "./pages/Contactus";
import LinuxDedicated from "./pages/dedicated/LinuxDedicated";
import WindowsDedicated from "./pages/dedicated/WindowDedicated";
import SelfManagedDedicatedLinux from "./pages/dedicated/Selfmanageddedicatedlinux";
import SelfManagedDedicatedWindow from "./pages/dedicated/SelfManagedDedicatedWindows";
import Backup from "./pages/security/Backup";
// import ChatForm from "./component/LetsTalk";
import AdminRoute from "./component/auth/AdminRoute.js";
import AdminDashboard from "./component/Admin/AdminDashboard";
import UpdateProfile from "./component/Admin/profile";
import Products from "./component/Admin/Product";
import AllUsers from "./component/Admin/AllUsers";
import AllQueries from "./component/Admin/AllQueries";
import Plans from "./component/Admin/Plans";
import UpdatePlan from "./component/Admin/UpdatePlans";
import FilterQuery from "./component/Admin/Quries";
import AlluserRoute from "./component/Admin/AllusersRoute.js";
// import Contact from "./pages/Contactus";
// import Career from "./component/career/Career";
import Landingpage from "./component/LandingPage.js";
import Login from "./component/Login.js";
import PopupForm from "./component/LetsTalk.js";

function App() {
  return (
    <>
      {/* <ChatForm /> */}
      <PopupForm />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/grow-with-us" element={<Landingpage />} />
        <Route path="/gpu" element={<GPU />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
        <Route path="/shared-hosting" element={<SharedHosting />} />
        <Route path="/linux-hosting" element={<LinusHosting />} />
        <Route path="/linux-reseller" element={<ResellerLinux />} />
        <Route path="/window-reseller" element={<ResellerWindow />} />
        <Route path="/window-hosting" element={<WindowsHostingShared />} />
        <Route path="/cloudvps" element={<CloudVPS />} />
        <Route
          path="/managed-vps-hosting/linux"
          element={<LinuxManagedVPS />}
        />
        <Route path="/managed-vps-hosting/window" element={<WindowManaged />} />
        <Route
          path="/self-managed-vps-hosting/linux"
          element={<LinuxSelfManagedVPS />}
        />
        <Route
          path="/self-managed-vps-hosting/window"
          element={<WindowSelfManagedVPS />}
        />
        <Route path="/dedicated" element={<Dedicated />} />
        <Route
          path="/fully-managed-dedicated-servers/linux"
          element={<LinuxDedicated />}
        />
        <Route
          path="/fully-managed-dedicated-servers/windows"
          element={<WindowsDedicated />}
        />
        <Route
          path="/self-managed-dedicated-servers/linux"
          element={<SelfManagedDedicatedLinux />}
        />
        <Route
          path="/self-managed-dedicated-servers/windows"
          element={<SelfManagedDedicatedWindow />}
        />

        {/* <Route path="/dedicated-india" element={<AsiaDedi />} /> */}
        <Route path="/security-ssl" element={<SSL />} />
        <Route path="/security-backup" element={<Backup />} />
        <Route path="/usepolicy" element={<UsePolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact-us" element={<ContactForm />} />
        <Route path="/privacypolicy" element={<PrivicyPolicy />} />
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/profile/:_id" element={<UpdateProfile />} />

          <Route path="admin/product" element={<Products />} />
          <Route path="admin/allusersRoute" element={<AlluserRoute />}>
            <Route path="users" element={<AllUsers />} />
          </Route>
          <Route path="admin/allqueries" element={<AllQueries />} />
          <Route path="admin/plan" element={<Plans />} />
          <Route path="admin/updateplan/:_id" element={<UpdatePlan />} />
          <Route path="admin/allquery/:title" element={<FilterQuery />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
