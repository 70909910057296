import React, { useState, useEffect } from "react";
import "./hosting.css";

import lr16 from "../../img/lr16.jpg";
import lr17 from "../../img/lr17.png";
import lr18 from "../../img/lr18.png";
import lr19 from "../../img/lr19.png";
import lr20 from "../../img/lr20.jpg";
import lr21 from "../../img/lr21.png";
import lr22 from "../../img/lr22.webp";
import lr23 from "../../img/lr23.webp";
import lr24 from "../../img/lr24.webp";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import linuxresellerbanner from "../../img/hosting.jpg";
import garantee from "../../img/garantee.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEarth,
  faIndianRupeeSign,
  faPhone,
  faStar,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../component/Footer";

import "react-slideshow-image/dist/styles.css";
import { faSignalMessenger } from "@fortawesome/free-brands-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
// import Modal from "../../component/Modal";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Form, Modal } from "react-bootstrap";

const ResellerLinux = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/singleplan/Linux-Hosting"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://sstcloud.in:8086/api/query/addquery",
        {
          title: "linux-Shared",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data?.success) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
      } else {
        toast.error("Something went wrong. Please try again.");
      }

      navigate("/");
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 992) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(5);
      }
    };

    updateSlidesToShow();

    window.addEventListener("resize", updateSlidesToShow);

    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, [slidesToShow]);
  const card2 = [
    {
      img: "./img/lr1.png",
      text: "Free Hosting Migration",
    },
    {
      img: "./img/lr2.svg",
      text: "Private nameservers",
    },
    {
      img: "./img/lr3.svg",
      text: "Domain reseller panel",
    },
    {
      img: "./img/lr4.svg",
      text: "SSD based servers",
    },
    {
      img: "./img/lr5.png",
      text: "24/7 Support",
    },
  ];
  const card3 = [
    {
      img: "./img/lr6.svg",
      h: "Daily backup",
      p: `Are you worried about accidental deletion of website data? Don't worry, we keep backups with us. We do take daily backups using Acronis cloud backup solution.`,
    },
    {
      img: "./img/lr7.svg",
      h: "Private Nameservers",
      p: `You have the option to use our name servers or you can use your own private name servers which would look like ns1.example.com and ns2.example.com. Benefit to having private name servers is mainly branding.`,
    },
    {
      img: "./img/lr8.svg",
      h: "Domain Reseller Panel",
      p: `Looking to register domains on behalf of your client! Register domains using domain reseller panel and earn profit. No need to pay anything extra for a domain reseller account.`,
    },
    {
      img: "./img/lr9.svg",
      h: "Overselling Enabled",
      p: `Only used space is considered. You can resell the space to which is free.`,
    },
    {
      img: "./img/lr10.svg",
      h: "SSD Based Servers",
      p: "Superfast SSD servers! SSD servers speed up the performance of your website.",
    },
    {
      img: "./img/lr11.svg",
      h: "cPanel/WHM",
      p: "Reseller hosting package comes with cPanel and Reseller WHM access. cPanel is the best choice for your Reseller Hosting, as it's a user friendly control panel.",
    },
  ];
  return (
    <>
      <ToastContainer />
      {isMobile ? <Header2 /> : <Header />}
      <div className="container marginbotm">
        <div className="row">
          <div className="col-md-6 col-sm-12 res1">
            <h2 style={{ color: "rgb(230,57,63)" }}>
              Linux Reseller Hosting - Delhi [NCR], India
            </h2>
            <p>Be your own Boss, Start your own business today.</p>
            <ul>
              <li>Private Nameservers</li>
              <li>Overselling Enabled</li>
              <li>Unlimited Domains</li>
              <li>Daily Backup</li>
              <li>Blazing fast performance</li>
              <li>cPanel/WHM, PHP, Wordpress, Joomla & Drupal</li>
            </ul>
            <button
              style={{
                // border: "1px solid rgb(230,57,63)",
                borderRadius: "10px",
                padding: "7px 20px",
                fontSize: "16px",

                color: "white",
                fontWeight: "bold",
                backgroundColor: "green",
                border: "none",
              }}
              onClick={() => {
                const hostingDiv = document.getElementById("start");
                if (hostingDiv) {
                  hostingDiv.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              View Plans
            </button>
          </div>
          <div className="col-md-6 col-sm-12 banner1">
            <img
              style={{ width: "100%", height: "90%" }}
              alt="img"
              src={linuxresellerbanner}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-evenly flex-wrap my-5">
        {card2.map((e) => (
          <>
            <div className="text-center">
              <img
                alt="er"
                src={e.img}
                style={{ height: "50px", width: "50px" }}
              />
              <h6>{e.text}</h6>
            </div>
          </>
        ))}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h6>RESELLER HOSTING</h6>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              With SST cloud services, you can enjoy the best web hosting
              services. Are you not satisfied with your web hosting? Then don’t
              worry <b>SSTCLOUD</b> is happy to help you. We offer you a
              complete package within your budget of web hosting with proper
              services. Now, Bring your Business online in minutes with{" "}
              <b>SSTCLOUD</b>. We will provide you a place where you can easily
              host and manage your website.
            </p>
            <p>
              <b>SSTCLOUD</b> enables you to start your own web hosting business
              by providing you with affordable and profitable option.{" "}
              <b>SSTCLOUD</b>
              makes managing several accounts by the provision of productive
              tools and 24x7 support. You can host unlimited domain names under
              your brand name and the most essential characteristic is that the
              overselling is enabled.
            </p>
          </div>
          <div className="col-md-6 col-sm-12"></div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1
              id="start"
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
                textAlign: "center",
              }}
            >
              RESELLER HOSTING PLANS - LINUX
            </h1>
          </div>
        </div>
        <div className="row text-center">
          {plan.map((e) => (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12 p-3">
                <div className="slide">
                  <div>
                    <h4 style={{ color: "rgb(230,57,63)", fontWeight: "700" }}>
                      {e.type}
                    </h4>

                    <p style={{ fontWeight: "600", fontSize: "18px" }}>
                      <FontAwesomeIcon icon={faIndianRupeeSign} /> {e.price} /
                      Month
                    </p>
                    <p>{e.p}</p>
                  </div>
                  <hr
                    style={{
                      width: "100%",
                    }}
                  />
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p1}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p2}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p3}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p4}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p5}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p6}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faEarth} />
                    {e.p7}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p8}
                  </p>
                  <button onClick={() => handleShow(e)} className="querybtn2">
                    Configure Now
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="http://localhost:3000/static/media/logo3.d16e8b77734d74fa9567.png"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6 className="text-light">Linux-Shared-Hosting</h6>
                  <button onClick={handleClose} className="querybtn">
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
                <form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      <div
        className="container-fluid my-5"
        style={{ backgroundColor: "#444444" }}
      >
        <div className="row text-center py-5">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-4 col-sm-12">
            <div className="d-flex justify-content-center align-items-center gap-5">
              <div className="boxshadow">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    lineHeight: "25px",
                    color: "white",
                  }}
                >
                  CALL
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    lineHeight: "19px",
                    color: "white",
                  }}
                >
                  +91 9015937199
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            {" "}
            <div className="d-flex justify-content-center align-items-center gap-5">
              <div className="boxshadow">
                <FontAwesomeIcon icon={faSignalMessenger} />
              </div>
              <div>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    lineHeight: "25px",
                    color: "white",
                  }}
                >
                  CALL
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    lineHeight: "19px",
                    color: "white",
                  }}
                >
                  +91 9015937199
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
                textAlign: "center",
              }}
            >
              GET STARTED WITH <b>SSTCLOUD</b>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p>
              We offer a 30-day money back guarantee. If you are not satisfied
              with our service, you may raise a refund request within the first
              30 days from the date of purchase.
            </p>
            <p>
              We will appreciate it if you let us know the reason for
              cancellation as this will help us understand the expectations of
              our customers and improve our services. Now, that's a fair deal,
              isn't it!
            </p>
            <p>Need help to choose the right hosting plan?</p>
            <div className="d-flex flex-wrap align-items-center gap-5">
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
              >
                VIEW PLANS
              </button>
              <NavLink className="navli">Talk To A Specialist</NavLink>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 garantee text-center">
            <img
              alt="img"
              src={garantee}
              style={{ height: "241px", width: "271px" }}
            />
          </div>
        </div>
      </div>
      <div className="bghome my-5">
        <div className="container py-5">
          <div className="row ">
            <div className="col-md-6 col-sm-12"></div>
            <div className="col-md-6 col-sm-12 homeend">
              <h1>NEED PROFESSIONAL HELP?</h1>
              <p className="py-4">
                Our in-house, certified experts are always available to answer
                your questions, get you started, and grow your presence online.
                You can call, chat or email us any time!
              </p>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                className="mx-3"
              >
                Email Us
              </button>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                className="mx-3"
              >
                Chat With Us
              </button>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                className="mx-3"
              >
                Call Us
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
                textAlign: "center",
              }}
            >
              BEST RATED WEB HOSTING COMPANY
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-center gap-5 align-items-center">
            <div className="d-flex align-items-center gap-3">
              <FontAwesomeIcon
                icon={faStar}
                style={{
                  fontSize: "20px",
                  fontWeight: "900",
                  lineHeight: "20px",
                  color: "#ffc850",
                }}
              />
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                4.9{" "}
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "500",
                  }}
                >
                  / 5
                </span>
              </p>{" "}
              |{" "}
              <img
                style={{ height: "32px", width: "78px" }}
                alt="img"
                src={lr22}
              />
            </div>
            <div className="d-flex align-items-center gap-3">
              <FontAwesomeIcon
                icon={faStar}
                style={{
                  fontSize: "20px",
                  fontWeight: "900",
                  lineHeight: "24px",
                  color: "#ffc850",
                }}
              />
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                8.5{" "}
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "500",
                  }}
                >
                  / 10
                </span>
              </p>{" "}
              |{" "}
              <img
                style={{ height: "32px", width: "78px" }}
                alt="img"
                src={lr23}
              />
            </div>
            <div className="d-flex align-items-center gap-3">
              <FontAwesomeIcon
                icon={faStar}
                style={{
                  fontSize: "20px",
                  fontWeight: "900",
                  lineHeight: "20px",
                  color: "#ffc850",
                }}
              />
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                4.7{" "}
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "500",
                  }}
                >
                  / 5
                </span>
              </p>{" "}
              |{" "}
              <img
                style={{ height: "32px", width: "78px" }}
                alt="img"
                src={lr24}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ height: "60vh", width: "100%", position: "relative" }}
      >
        <div className="row py-5">
          <img
            style={{
              height: "52px",
              width: "108px",
              position: "absolute",
              top: "10%",
              left: "5%",
              cursor: "pointer",
            }}
            alt="img1"
            src={lr16}
          />
          <img
            style={{
              height: "32px",
              width: "78px",
              position: "absolute",
              top: "35%",
              left: "0%",
              cursor: "pointer",
            }}
            alt="img1"
            src={lr17}
          />
          <img
            style={{
              height: "32px",
              width: "78px",
              position: "absolute",
              top: "50%",
              left: "12%",
              cursor: "pointer",
            }}
            alt="img1"
            src={lr18}
          />
          <img
            style={{
              height: "52px",
              width: "78px",
              position: "absolute",
              top: "10%",
              left: "80%",
              cursor: "pointer",
            }}
            alt="img1"
            src={lr19}
          />
          <img
            style={{
              height: "32px",
              width: "78px",
              position: "absolute",
              top: "50%",
              left: "90%",
              cursor: "pointer",
            }}
            alt="img1"
            src={lr20}
          />
          <img
            style={{
              height: "72px",
              width: "158px",
              position: "absolute",
              top: "60%",
              left: "70%",
              cursor: "pointer",
            }}
            alt="img1"
            src={lr21}
          />
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-8 col-sm-12 text-center pt-5 py-5">
            {" "}
            <h1>INSTALL THESE APPS IN ONE-CLICK</h1>
            <p>
              Deploy unlimited apps and experience best hosting for your
              website. Application installer allows you to install apps with a
              single click. All apps have been tested and proven to work
              perfectly with our hosting services.
            </p>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
      </div>
      {/* <div className="container slide1">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="my-5"
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
                textAlign: "center",
              }}
            >
              OUR CUSTOMERS
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 d-flex align-items-center">
            <p>
              Customers trust us to handle their hosting needs. Nurturing
              relationship with clients since 2006. With our unique approach and
              innovative products, we are providing services to more than 20000+
              Customers.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <Slideshow1 />
          </div>
        </div>
      </div> */}
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="my-5"
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
                textAlign: "center",
              }}
            >
              WHY CHOOSE <b>SSTCLOUD</b> NETWORKS
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th className="tablecol" scope="col"></th>
                  <th scope="col" className="tablecol">
                    <img
                      style={{ height: "50px", width: "100px" }}
                      src={lr12}
                      alt="img"
                    />
                  </th>
                  <th scope="col" className="tablecol">
                    <img
                      style={{ height: "50px", width: "100px" }}
                      src={lr12}
                      alt="img"
                    />
                  </th>
                  <th scope="col" className="tablecol">
                    <img
                      style={{ height: "50px", width: "100px" }}
                      src={lr13}
                      alt="img"
                    />
                  </th>
                  <th scope="col" className="tablecol">
                    <img
                      style={{ height: "50px", width: "100px" }}
                      src={lr14}
                      alt="img"
                    />
                  </th>
                  <th scope="col" className="tablecol">
                    <img
                      style={{ height: "50px", width: "100px" }}
                      src={lr15}
                      alt="img"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Regular Price
                  </th>
                  <td className="tablecol">
                    <p
                      style={{
                        color: "#434343",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        style={{ fontSize: "12px" }}
                      />
                      425<span style={{ fontSize: "12px" }}>/mo</span>
                    </p>
                  </td>
                  <td className="tablecol">
                    <p
                      style={{
                        color: "gray",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        style={{ fontSize: "12px" }}
                      />
                      1,125<span style={{ fontSize: "12px" }}>/mo</span>
                    </p>
                  </td>
                  <td className="tablecol">
                    <p
                      style={{
                        color: "gray",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        style={{ fontSize: "12px" }}
                      />
                      1,399<span style={{ fontSize: "12px" }}>/mo</span>
                    </p>
                  </td>
                  <td className="tablecol">
                    <p
                      style={{
                        color: "gray",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        style={{ fontSize: "12px" }}
                      />
                      1,849<span style={{ fontSize: "12px" }}>/mo</span>
                    </p>
                  </td>
                  <td className="tablecol">
                    <p
                      style={{
                        color: "gray",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        style={{ fontSize: "12px" }}
                      />
                      1,695<span style={{ fontSize: "12px" }}>/mo</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    SSD Servers
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Disk Space
                  </th>
                  <td className="tablecol">
                    <p>20 GB SSD</p>
                  </td>
                  <td className="tablecol">50 GB</td>
                  <td className="tablecol">100 GB</td>
                  <td className="tablecol">50 GB</td>
                  <td className="tablecol">40 GB</td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Bandwidth
                  </th>
                  <td className="tablecol">Unlimited</td>
                  <td className="tablecol">500GB</td>
                  <td className="tablecol">1000 GB</td>
                  <td className="tablecol">900 GB</td>
                  <td className="tablecol">700 GB</td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    SSL Certificate
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Domain Reseller Account
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Website Backup & Restore
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    LiteSpeed
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    CDN
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Immunify
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Free Website Migration
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">Paid</td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Application Installer
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Free Malware Scan & Removal
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Instant Chat Response
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  >
                    Support in Local Languages
                  </th>
                  <td className="tablecol">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                  <td className="tablecol">
                    {" "}
                    <FontAwesomeIcon
                      icon={faClose}
                      style={{ color: "rgb(230,57,63)" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    style={{ color: "#434343" }}
                    className="tablecol "
                    scope="row"
                  ></th>
                  <td className="tablecol">
                    {" "}
                    <button
                      style={{
                        fontSize: "11px",
                        fontWeight: "800",
                        lineHeight: "20px",
                        border: "none",
                        backgroundColor: "#DE4047",
                        color: "white",
                        borderRadius: "2px",
                        padding: "5px 10px",
                      }}
                    >
                      CONFIGURE NOW
                    </button>
                  </td>
                  <td className="tablecol"> </td>
                  <td className="tablecol"> </td>
                  <td className="tablecol"> </td>
                  <td className="tablecol"> </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              FEATURES
            </h1>
          </div>
        </div>
        <div className="row">
          {card3.map((e, index) => (
            <>
              <div
                key={index}
                className="col-md-4 col-sm-12 p-5 crd3col text-center"
              >
                <img
                  src={e.img}
                  alt="img"
                  style={{
                    height: "70px",
                    width: "70px",
                  }}
                />
                <h3 className="py-3 maph3">{e.h}</h3>
                <p className="mapp">{e.p}</p>
              </div>
            </>
          ))}
        </div>
      </div>
      <div
        className="container-fluid py-5"
        style={{ backgroundColor: "rgb(65,57,69)" }}
      >
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-center align-items-center flex-wrap gap-3">
            <p
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                paddingTop: "10px",
                color: "white",
              }}
            >
              SUBSCRIBE FOR
              <br /> NEW OFFERS*
            </p>
            <input
              style={{
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontSize: "18px",
              }}
              type="email"
              placeholder="enter your email address"
            />
            <button
              style={{
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "rgb(230,57,63)",
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT LINUX RESELLER WEB HOSTING.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is reseller hosting?</h1>
            <p className="hostp">
              Reseller hosting is a type of web hosting wherein the account
              owner has the ability to use his or her allotted hard drive space
              and bandwidth to host websites on behalf of third parties. The
              reseller purchases the host's services wholesale and then sells
              them to customers, possibly for a profit. A certain portion of
              hard drive space and bandwidth is allocated to the reseller
              account. The reseller may rent a dedicated server from a hosting
              company or resell shared hosting services.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is Linux reseller hosting?</h1>
            <p className="hostp">
              Linux reseller hosting is a type of reseller hosting. You can host
              HTML, PHP, WordPress websites with Linux reseller hosting. You
              will get a cPanel/WHM panel to manage your sites. You can assign
              particular cPanel to a particular website.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is overselling?</h1>
            <p className="hostp">
              Overselling means suppose you purchased a plan of 5 GB SSD
              webspace and you allocate all space to your clients, but all of
              your clients combined are actually using 3GB space and 2 GB space
              is still remaining. In this case, you can resell the space to
              other clients.
              <br />
              Note: Only used space is considered.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is a domain reseller?</h1>
            <p className="hostp"></p>A domain reseller account is a service
            through which you can register/renew/transfer your client’s domain.
            It is a fully white-label service. All domains branded as Registered
            by Your Company Name in Whois.
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What do you mean by private nameservers?</h1>
            <p className="hostp">
              A private name server is a completely white-label solution for
              your network services. Once availed, your clients won’t be able to
              know you are reselling from our company.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the difference between shared and reseller hosting?
            </h1>
            <p className="hostp">
              In shared hosting, you will get one cPanel/WHM through which you
              have to manage your websites. In reseller hosting, you can create
              an unlimited cPanel/WHM and manage your sites. You can share
              particular cPanel/WHM to your clients/developers to design manage
              a website.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible for me to create packages as per my client’s needs?
            </h1>
            <p className="hostp">
              Yes, It is definitely possible for you to create a package as per
              your client’s needs. Just reach out to our experts and they will
              provide the best solution that fits your requirements.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to purchase additional cPanel accounts along with
              reseller hosting plan?
            </h1>
            <p className="hostp">
              Unfortunately, it's not possible to purchase additional cPanel
              accounts along with reseller hosting account. You need to upgrade
              to higher plan.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to purchase additional space along with reseller
              hosting account?s
            </h1>
            <p className="hostp">No, you need to upgrade to higher plan.</p>
          </div>
          <div className="col-md-6 col-sm-12 py-5"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResellerLinux;
