import React, { useState, useEffect } from "react";
import logo from "../../img/sstcloudlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";
import { Close as CloseIcon } from "@mui/icons-material";

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Select,
  InputLabel,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { useNavigate } from "react-router-dom";

const Plans = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [showSideNav2, setShowSideNav2] = useState(false);

  const [youtube, setYoutube] = useState([]);
  const youtubeseo = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/allplans"
      );
      setYoutube(data?.data);
      console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    youtubeseo();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true);
        setDrawerOpen(false);
      } else {
        setShowSideNav2(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const [open, setOpen] = useState(false);

  // const [selectedPackage,setSelectedPackage] = useState(null);
  const [formData, setFormData] = useState({
    category: "",
    price: "",
    type: "",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    p10: "",
    p11: "",
    p12: "",
    p13: "",
    p14: "",
    p15: "",
    p16: "",
    p17: "",
    p18: "",
    p19: "",
    p20: "",
    p21: "",
  });

  const handleOpen = () => setOpen(true);
  const handleCloses = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      const response = await axios.post(
        "https://sstcloud.in:8086/api/plan/newPlan",
        formData
      );
      console.log(response);
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflow: "auto",
    borderRadius: "5px",
  };

  // const handleSubmited = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.put(
  //       `https://sstcloud.in:8086/api/plan/updateplan/${selectedPackage.id}`,
  //       formData
  //     );
  //     console.log(response);
  //     handleClose();
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };
  const deletePlan = async (_id) => {
    try {
      const response = await axios.delete(
        `https://sstcloud.in:8086/api/plan/deleteplan/${_id}`
      );
      if (response.data.success) {
        alert("Plan deleted successfully");
        // Optionally, refresh the data or update the state to remove the deleted plan from the list
      } else {
        alert("Failed to delete plan");
      }
    } catch (error) {
      console.error("Error deleting plan:", error);
      alert("An error occurred while deleting the plan");
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img src={logo} alt="logo" className="dashlogo" />
          </Typography>
          <div className="ms-auto">
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faPerson}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <div>{auth?.user?.name}</div>
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem
                  onClick={() => {
                    navigate("/dashboard/admin/profile");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPerson}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Profile
                </MenuItem> */}
                <MenuItem onClick={handleClose}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? 250 : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 250 : 50,
            boxSizing: "border-box",
            transition: "width 0.3s",
            paddingTop: "100px",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {drawerOpen && !showSideNav2 ? <SideNav /> : <SideNav2 />}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerOpen ? 250 : 50}px)`,
          transition: "width 0.3s",
        }}
      >
        <Toolbar />
        <Typography variant="p" component="div" sx={{ flexGrow: 1, mb: 3 }}>
          Dashboard / Plan
        </Typography>

        <div>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Add Plans
          </Button>
          <Modal
            open={open}
            onClose={handleCloses}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography id="modal-title" variant="h6" component="h2">
                  Fill Plan Details
                </Typography>
                <IconButton onClick={handleCloses}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <form onSubmit={handleSubmit}>
                <InputLabel>Category</InputLabel>
                <Select
                  fullWidth
                  label="Category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                >
                  <MenuItem value="Linux-Hosting">Linux-Hosting</MenuItem>
                  <MenuItem value="Windows-Hosting">Windows-Hosting</MenuItem>
                  <MenuItem value="Linux-Reseller-Hosting">
                    Linux-Reseller-Hosting
                  </MenuItem>
                  <MenuItem value="Windows-Reseller-Hosting">
                    Windows-Reseller-Hosting
                  </MenuItem>
                  <MenuItem value="Fully-Managed-Linux-VPS-Servers ">
                    Fully-Managed-Linux-VPS-Servers{" "}
                  </MenuItem>
                  <MenuItem value="Fully-Managed-Windows-VPS-Servers ">
                    Fully-Managed-Windows-VPS-Servers{" "}
                  </MenuItem>
                  <MenuItem value="Self-Managed-Linux-VPS-Servers ">
                    Self-Managed-Linux-VPS-Servers{" "}
                  </MenuItem>
                  <MenuItem value="Self-Managed-Windows-VPS-Servers ">
                    Self-Managed-Windows-VPS-Servers{" "}
                  </MenuItem>
                  <MenuItem value="Fully-Managed-Linux-Dedicated-Servers ">
                    Fully-Managed-Linus-Dedicated-Servers{" "}
                  </MenuItem>
                  <MenuItem value="Fully-Managed-Windows-Dedicated-Servers ">
                    Fully-Managed-Windows-Dedicated-Servers{" "}
                  </MenuItem>
                  <MenuItem value="Fully-Managed-Linux-Dedicated-Servers ">
                    Self-Managed-Linus-Dedicated-Servers{" "}
                  </MenuItem>
                  <MenuItem value="Fully-Managed-Windows-Dedicated-Servers ">
                    Self-Managed-Windows-Dedicated-Servers{" "}
                  </MenuItem>
                  <MenuItem value="SSL">SSL </MenuItem>
                  <MenuItem value="Backup">Backup</MenuItem>
                </Select>
                <InputLabel>Type Of Package</InputLabel>
                <Select
                  fullWidth
                  label="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                >
                  <MenuItem value="STARTER">STARTER</MenuItem>
                  <MenuItem value="STANDARD">STANDARD</MenuItem>
                  <MenuItem value="POPULAR">POPULAR</MenuItem>
                  <MenuItem value="BUSINESS">BUSINESS</MenuItem>
                </Select>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  required
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="P1"
                  name="p1"
                  value={formData.p1}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P2"
                  name="p2"
                  value={formData.p2}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P3"
                  name="p3"
                  value={formData.p3}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P4"
                  name="p4"
                  value={formData.p4}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P5"
                  name="p5"
                  value={formData.p5}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P6"
                  name="P6"
                  value={formData.p6}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P7"
                  name="P7"
                  value={formData.p7}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P8"
                  name="P8"
                  value={formData.p8}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P9"
                  name="P9"
                  value={formData.p9}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P10"
                  name="P10"
                  value={formData.p10}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P11"
                  name="P11"
                  value={formData.p11}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P12"
                  name="P12"
                  value={formData.p12}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P13"
                  name="P13"
                  value={formData.p13}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P14"
                  name="P14"
                  value={formData.p14}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P15"
                  name="P15"
                  value={formData.p15}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P16"
                  name="P16"
                  value={formData.p16}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P17"
                  name="P17"
                  value={formData.p17}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P18"
                  name="P18"
                  value={formData.p18}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P19"
                  name="P19"
                  value={formData.p19}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P20"
                  name="P20"
                  value={formData.p20}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="P21"
                  name="P21"
                  value={formData.p21}
                  onChange={handleChange}
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>
        </div>

        <div className="container-fluid my-4">
          <div className="row">
            {youtube?.map((e, index) => (
              <>
                <div className="col-lg-3 col-md-6 col-sm-12 p-3 text-center my-3">
                  <h5>{e.category}</h5>
                  <div
                    key={index}
                    style={{
                      width: "230px",
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                      boxShadow: "5px 5px 10px lightgray",
                    }}
                    className=""
                  >
                    <div>
                      {" "}
                      <h1 className="button2 repeath1">{e.type}</h1>
                      <div
                      // style={{
                      //   backgroundColor: "#112D52",
                      //   padding: "10px 0",
                      //   color: "white",
                      // }}
                      >
                        <h3>{e.price}</h3>
                        <p>Per Month</p>
                      </div>
                    </div>

                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p1}</b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p2} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p3}</b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p4} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p5} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p6} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p7}</b>
                    </p>

                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p8} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b> {e.p9}</b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b> {e.p10}</b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p11} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p12} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p13}</b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b> {e.p14}</b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p15} </b>
                    </p>

                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p16} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p17} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p18} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p19} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p20} </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      <b>{e.p21} </b>
                    </p>
                    <button
                      onClick={() => {
                        navigate(`/dashboard/admin/updateplan/${e._id}`);
                      }}
                      className="btn btn-primary m-2"
                    >
                      Edit Plan
                    </button>
                    <button
                      onClick={() => {
                        deletePlan(e._id);
                      }}
                      className="btn btn-danger m-2"
                    >
                      Delete Plan
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Plans;
