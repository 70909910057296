import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../img/sstcloudlogo.png";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const redirectToFacebook = () => {
    window.open("https://www.facebook.com", "_blank");
  };
  const redirectToInstagram = () => {
    window.open(
      "https://www.instagram.com/sstcloud.server/?img_index=1",
      "_blank"
    );
  };
  const redirectToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/edu-tantra/", "_blank");
  };
  return (
    <>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-md-2 col-sm-12 text-center">
            <img
              style={{
                height: "120px",
                width: "150px",
                padding: "0",
                margin: "0",
              }}
              alt="logo"
              src={logo}
            />
          </div>
          <div className="col-md-2 col-sm-12">
            <h6>COMMUNITY/SOCIAL</h6>
            <p className="footerp">
              <NavLink className="footernavlink">sstcloud Blog</NavLink>
            </p>
            <hr />
            <p className="footerp">
              <NavLink className="footernavlink" onClick={redirectToFacebook}>
                FaceBook
              </NavLink>
            </p>
            <hr />
            <p className="footerp">
              <NavLink className="footernavlink" onClick={redirectToInstagram}>
                Instagram
              </NavLink>
            </p>
            <hr />
            <p className="footerp">
              <NavLink className="footernavlink" onClick={redirectToLinkedIn}>
                Linkedin
              </NavLink>
            </p>
            <hr />
          </div>
          <div className="col-md-2 col-sm-12">
            <h6>SERVICE INFORMATION</h6>
            <p className="footerp">
              <NavLink to="/usepolicy" className="footernavlink">
                Acceptable Use Policy
              </NavLink>
            </p>
            <hr />
            <p className="footerp">
              <NavLink to="/terms" className="footernavlink">
                Terms of Service
              </NavLink>
            </p>
            <hr />
            <p className="footerp">
              <NavLink to="/privacypolicy" className="footernavlink">
                Privacy Policy
              </NavLink>
            </p>
          </div>
          <div className="col-md-3 col-sm-12">
            <h6>CONTACT US</h6>
            <p className="footerp">
              <span style={{ color: "gray" }}>Sales - </span>
              <NavLink className="footernavlink">sales@sstcloud.com</NavLink>
            </p>
            <hr />
            <p className="footerp">
              <span style={{ color: "gray" }}>Support -</span>
              <NavLink className="footernavlink"> support@sstcloud.com</NavLink>
            </p>
            <hr />
            <p className="footerp">
              <span style={{ color: "gray" }}>Billing - </span>
              <NavLink className="footernavlink">billing@sstcloud.com</NavLink>
            </p>
            <hr />
            <p className="footerp">
              <span style={{ color: "gray" }}>Complain - </span>
              <NavLink className="footernavlink">complain@sstcloud.com</NavLink>
            </p>
          </div>
          <div className="col-md-3 col-sm-12">
            <h6>SUPPORT/LOGIN</h6>
            <p className="footerp">
              <NavLink className="footernavlink">Live Chat</NavLink>
            </p>
            <hr />
            <p className="footerp">
              <NavLink className="footernavlink">Open Tickets</NavLink>
            </p>
            <hr />
            {/* <p className="footerp">
              <NavLink className="footernavlink">Knowledge Base</NavLink>
            </p>
            <hr /> */}
            <p className="footerp">
              <NavLink className="footernavlink" to="/contact-us">
                Contact-Us
              </NavLink>
            </p>
            <hr />
            <p className="footerp">
              <NavLink className="footernavlink" to="/login">
                Login
              </NavLink>
            </p>
          </div>
        </div>
      </div>

      <div className="fbottom">
        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-md-6 col-sm-12 px-5">
              <p className="text-center">
                © 2024 sstcloud Pvt. Ltd. All Rights Reserved.
              </p>
            </div>
            <div className="col-md-3 col-sm-12"></div>
            <div className="col-md-3 col-sm-12 px-5 text-center d-flex justify-content-around">
              <FontAwesomeIcon className="footerfont" icon={faFacebookF} />
              <FontAwesomeIcon className="footerfont" icon={faTwitter} />
              <FontAwesomeIcon className="footerfont" icon={faInstagram} />
              <FontAwesomeIcon className="footerfont" icon={faLinkedin} />
              <FontAwesomeIcon className="footerfont" icon={faPhotoFilm} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
