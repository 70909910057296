import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../img/sstcloudlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./header.css";
import Modal from "./Modal";
// import axios from "axios";
import { useAuth } from "./auth/Auth";

const Header2 = () => {
  const [auth, setAuth] = useAuth();

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <NavLink
            style={{
              color: "red",
              fontWeight: "400",
            }}
            className="navbar-brand px-4"
            to="/"
          >
            <img
              style={{ height: "65px", width: "138px" }}
              src={logo}
              alt="logo"
            />{" "}
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto px-5 mb-2 mb-lg-0">
              <li className="nav-item mx-2 my-0">
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Home <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                >
                  <Modal name="Request Your Free Quote" />
                </NavLink>

                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  {!auth.user ? (
                    <li style={{ listStyle: "none" }} className="topnav1">
                      <div>
                        <div>
                          <Link
                            type="button"
                            style={{ textDecoration: "none", color: "black" }}
                            to="/login"
                          >
                            Login
                          </Link>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li
                      className="topnav1 dropdown"
                      style={{ listStyle: "none" }}
                    >
                      <div className="dropdown" style={{ zIndex: "1000" }}>
                        <Link
                          className="nav-link dropdown-toggle"
                          role="button"
                          id="navbarDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {auth?.user?.name}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ zIndex: "100000" }}
                        >
                          <li>
                            <NavLink
                              to={"/dashboard/admin"}
                              className="dropdown-item  bg-light"
                            >
                              Dashboard
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className="dropdown-item bg-light text-dark"
                              onClick={logout}
                              to="/"
                            >
                              Logout
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                </NavLink>

                {/* <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Login <FontAwesomeIcon icon={faAngleRight} />
                </NavLink> */}
              </li>
              <li className="nav-item mx-2 my-0">
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  24x7 Support : +91 90159 37199{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>
              <li className="nav-item mx-2 my-0">
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Sales : +91 90159 37199{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>

              <li className="nav-item mx-2 my-0">
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/contact-us"
                >
                  Contact Us <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>
              <li className="nav-item mx-2 my-0">
                <NavLink
                  style={{
                    backgroundColor: "lightgreen",
                    color: "white",
                    height: "30px",
                    width: "30px",
                    fontSize: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />{" "}
                </NavLink>
              </li>
              <li className="nav-item mx-2 my-0">
                <h5 style={{ color: "black", fontWeight: "600" }}>Hosting</h5>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/linux-hosting"
                >
                  Linux Shared Hosting <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/window-hosting"
                >
                  Windows Shared Hosting <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/linux-reseller"
                >
                  Linux Reseller Hosting <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/window-reseller"
                >
                  Windows Reseller Hosting{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>
              <li className="nav-item mx-2 my-0">
                <h5 style={{ color: "black", fontWeight: "600" }}>
                  CLOUD SERVER
                </h5>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/managed-vps-hosting/linux"
                >
                  Linux Cloud Server Managed{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/managed-vps-hosting/window"
                >
                  Windows Cloud Server Managed{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/self-managed-vps-hosting/linux"
                >
                  Linux Cloud Server Self-Managed{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/self-managed-vps-hosting/window"
                >
                  Windows Cloud Self-Managed{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>
              <li className="nav-item mx-2 my-0">
                <h5 style={{ color: "black", fontWeight: "600" }}>SERVERS</h5>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/dedicated"
                >
                  Dedicated <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>
              <li className="nav-item mx-2 my-0">
                <h5 style={{ color: "black", fontWeight: "600" }}>
                  SECURITY & BACKUP
                </h5>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/security-ssl"
                >
                  SSL <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Backup <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>

              <li className="nav-item mx-2 my-0">
                <h5 style={{ color: "black", fontWeight: "600" }}>Know More</h5>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  About <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
                <NavLink
                  style={{
                    color: "red",
                    fontWeight: "400",
                  }}
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Careers{" "}
                  <span
                    style={{
                      padding: "5px",
                      backgroundColor: "rgb(255,221,64)",
                      borderRadius: "10px",
                      color: "black",
                      fontWeight: "400",
                    }}
                  >
                    HIRING
                  </span>{" "}
                  <FontAwesomeIcon icon={faAngleRight} />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header2;
