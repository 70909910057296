import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../../img/sstcloudlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { ToastContainer, toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

const UpdateProfile = () => {
  const { _id } = useParams();
  const [auth, setAuth] = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [post, setPost] = useState("");
  const [loading, setLoading] = useState(true); // New state for loading

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `https://sstcloud.in:8086/api/user/singleuser/${_id}`
      );
      if (response.data.success) {
        toast.success("Successfully fetched user details.");
        const { name, phone, email, role, post } = response.data.user;
        setName(name);
        setPhone(phone);
        setEmail(email);
        setRole(role);
        setPost(post);
        // console.log({ name, phone, email, role }); // Log the data
      } else {
        toast.error("Failed to fetch user details.");
      }
    } catch (error) {
      toast.error("Failed to fetch user details.");
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchUserDetails();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(
        `https://sstcloud.in:8086/api/user/updateprofile/${_id}`,
        { name, phone, role, post }
      );

      if (response.data.success) {
        toast.success("Profile updated successfully.");
      } else {
        toast.error("Failed to update profile.");
      }
    } catch (error) {
      toast.error("Failed to update profile.");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showSideNav2, setShowSideNav2] = useState(false);

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true);
        setDrawerOpen(false);
      } else {
        setShowSideNav2(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (loading) {
    return <div>Loading...</div>; // Render loading state
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img src={logo} alt="logo" className="dashlogo" />
          </Typography>
          <div className="ms-auto">
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faPerson}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <div>{auth?.user?.name}</div>
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? 250 : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 250 : 50,
            boxSizing: "border-box",
            transition: "width 0.3s",
            paddingTop: "100px",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {drawerOpen && !showSideNav2 ? <SideNav /> : <SideNav2 />}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerOpen ? 250 : 50}px)`,
          transition: "width 0.3s",
        }}
      >
        <Toolbar />
        <ToastContainer />

        <Box mt={3}>
          <Box sx={{ maxWidth: 400, mx: "auto", mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Update Profile
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                value={email}
                fullWidth
                margin="normal"
                disabled
              />
              <InputLabel>Status</InputLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
              >
                <MenuItem value="active">active</MenuItem>
                <MenuItem value="disabled">disabled</MenuItem>
              </Select>
              <InputLabel>Post</InputLabel>
              <Select
                value={post}
                onChange={(e) => setPost(e.target.value)}
                fullWidth
              >
                <MenuItem value="superadmin">superadmin</MenuItem>
                <MenuItem value="admin">admin</MenuItem>
              </Select>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Update
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateProfile;
