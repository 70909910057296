import React, { useState, useEffect } from "react";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";

const UsePolicy = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="container marginbotm">
        <div className="row">
          <div className="col-sm-12">
            <h3
              className="my-2"
              style={{
                fontSize: "25px",
                lineHeight: "30px",
                fontWeight: "600",
                color: "rgb(230,57,63)",
              }}
            >
              SstCloud ACCEPTABLE USAGE POLICY
            </h3>
            <p className="py-2 policyp">
              SstCloud.com provides services to clients all around the world.
              SstCloud has the responsibility to protect each client and provide
              them with the best services available. The following guidelines
              were designed in an attempt to ensure that we can do such.
            </p>
            <p className="py-2 policyp">
              SstCloud will be the sole arbiter as to what constitutes a
              violation of these provisions.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-sm-12">
            <h6>General:</h6>
            <p className="py-2 policyp">
              Services provided by SstCloud may be used for lawful purposes
              only. Transmission, storage, or presentation of any information,
              data, or material in violation of any United States Federal,
              State, or City law is prohibited.
            </p>
            <p className="py-2 policyp">
              The subscriber agrees to indemnify and hold harmless SstCloud from
              any claims resulting from the use of the service which damages the
              subscriber or any other party.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-sm-12">
            <h6>Content:</h6>
            <p className="py-2 policyp">
              <b>
                Examples of unacceptable content include, but are not limited
                to:
              </b>
            </p>
            <ul>
              <li className="policyp">
                Copyrighted media, without authority to distribute or display
              </li>
              <li className="policyp">Unlicensed applications</li>
              <li className="policyp">Cracking programs or key generators</li>
              <li className="policyp">
                High Yield investment programs or HYIPs and related monitors,
                toplists etc.
              </li>
              <li className="policyp">
                Child pornography or kidnapping material
              </li>
              <li className="policyp">
                Fraudulent activity, phishing, pyramid schemes, chain letters,
                forgery, or impersonation
              </li>
              <li className="policyp">
                Terrorist propaganda, racist material, or bomb/weapon
                instructions
              </li>
              <li className="policyp">
                Virus/Virii, Spyware, Malware, or other infectious material
              </li>
              <li className="policyp">
                Material protected by trade secret or other statute
              </li>
              <li className="policyp">Additionally, SstCloud prohibits:</li>
              <li className="policyp">
                IRC and associated programs (including psybnc, "bots", etc...)
              </li>
              <li className="policyp">
                Game servers, voice servers and related applications.
              </li>
              <li className="policyp">
                Scripts commonly used for abuse, attacks, or flooding
              </li>
              <li className="policyp">
                Threatening, harrassing, or obscene content
              </li>
              <li className="policyp">
                Legal adult content is permitted on all SstCloud product lines.
                The viewing/downloading or possession of such information/data
                by SstCloud clientele is not governed by SstCloud policy, and is
                at your own discretion/free-will, and may be governed by your
                state/federal government.
              </li>
            </ul>
            <p className="policyp">
              Links to unacceptable content, as defined above, are also
              prohibited.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-sm-12">
            <h6>Online Activity:</h6>
            <p className="policyp">
              Any attempts to undermine or cause harm to the SstCloud equipment,
              network, operations, or clientele is STRICTLY prohibited. SstCloud
              will pursue legal action to the fullest extent for all
              abuse/violation of such.
            </p>
            <p className="policyp">
              Any activity deemed damaging or abusive, whether unlawful or
              considered to be detrimental to the operations of SstCloud, is
              prohibited. This includes, but is not limited to:
            </p>
            <ul>
              <li className="policyp">Denial of Service Attacks (DOS/DDOS)</li>
              <li className="policyp">Unnecessary Port Scans</li>
              <li className="policyp">Mail Bombing</li>
              <li className="policyp">
                Unsolicited Advertising via IM, Forums, Newsgroups, Email, etc..
              </li>
            </ul>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-sm-12">
            <h6>Bulk Email:</h6>
            <p className="policyp">
              We understand that bulk email is an important mechanism for
              keeping people informed. However, spamming (unsolicited
              advertising), from the SstCloud network, or spamvertising
              (unsolicited advertising of) sites hosted on the SstCloud network
              is STRICTLY prohibited.
            </p>
            <p className="policyp">
              <b>Legitimate bulk email meets the following criteria:</b>
            </p>
            <ul>
              <li className="policyp">
                Messages include: Valid headers, removal instructions, and
                information on the method by which the subscribed address was
                obtained.
              </li>
              <li className="policyp">
                All bulk email senders must post privacy policies, in
                association with the sending domains. Co-registration is not
                prohibited, but explanations of the sharing of information must
                be both publicly available and included in the confirmation
                mechanism for list subscription.
              </li>
              <li className="policyp">
                If SstCloud approaches a client for evidence of confirmation,
                this request must be honoured within 24 hours.
              </li>
              <li className="policyp">
                Failure to meet the acceptable criteria for bulk mail may lead
                to immediate termination.
              </li>
              <li className="policyp">
                Additionally, if such actions have caused mail servers or IP
                address ranges on the SstCloud network to be blacklisted,
                SstCloud reserves the right to terminate customer account and
                fine the customer.
              </li>
            </ul>
            <h6 className="mt-5">Policy Violations:</h6>
            <p className="policyp">
              SstCloud may, at any time, audit systems for any data/activity
              showing potential policy violations.
            </p>
            <h6 className="mt-5">Warnings:</h6>
            <p className="policyp">
              The SstCloud Abuse Dept makes an attempt to contact the client,
              via email, when the first sign of a possible violation is noted.
              These alerts are a courtesy. Responses to such alerts are
              required. If an alert is received from any SstCloud staff member,
              the client is responsible for acknowledging his/her understanding
              and citing any action planned or taken, within 24 hours of the
              notice. Failure to do so may lead to suspension. Repeated failure
              to do so may result in termination.
            </p>
            <h6 className="my-5">Suspension/Deactivation:</h6>
            <p className="policyp">
              If any terms or conditions are failed to be followed, the client
              risks service suspension or termination. SstCloud reserves the
              right to remove any account, without prior notice. If SstCloud
              deactivates an account for violating policy, the client will
              forfeit any rights to a refund. No refunds for advance payments
              will be granted for deactivated accounts.
            </p>
            <h6 className="my-5">Unauthorized Usage:</h6>
            <p className="policyp">
              In any case involving unauthorized parties utilizing a SstCloud
              service for abuse/violations, the client will be responsible for
              stopping all affected applications, until the source of the
              problem is found and corrected. SstCloud may opt to disable all
              services until the client can perform such an investigation.
            </p>
            <p className="policyp">
              In general, the client is responsible for maintaining a secure
              system. The client is expected to monitor his/her system(s) and/or
              service(s). Resource usage should be maintained within the level
              outlined in the service contract. Abuse caused by end-users or
              unauthorized parties may increase resource usage and it should be
              noted that overage fees may apply.
            </p>
            <h6 className="my-5">Resource Usage:</h6>
            <p className="policyp">
              SstCloud will be the sole arbiter as to what constitutes a
              violation of resource usage limitations.
            </p>
            <h6 className="my-5">Bandwidth:</h6>
            <p className="policyp">
              Overage fees will apply to any service utilizing more than it's
              assigned bandwidth allocation, except for those unmetered
              bandwidth vpses and servers. Overages will be calculated based on
              overall amount of GigaBytes (GB) transferred in a monthly period,
              unless a service is sold based on Megabits per Second (MBPS).
              Services sold by MBPS, will be calculated via the industry
              standard 95% method, unless specified in contract otherwise.
              Client is responsible for all usage, and any corresponding
              overages fees, that result from a service that may be hacked
              and/or flooding. SstCloud may require a deposit should a client
              utilize/require large amounts of bandwidth beyond their standard
              bandwidth allocation.
            </p>
            <h6 className="my-5">Filtering:</h6>
            <p className="policyp">
              SstCloud reserves the right to block/filter or throttle ports
              and/or ip address ranges, at its discretion. This may include, for
              example, blocking Port 6667 (IRC), denying access from a
              known-spamming IP address range, or limiting the speed of
              point-to-point file-sharing programs (Kazaa, Limewire, Donkey,
              etc...). This may also include giving priority to certain traffic
              types, such as Voice-over-IP (VOIP) phone call transmissions.
            </p>
            <h6 className="my-5">
              Cloud Server or Virtual Private Servers (VPS):
            </h6>
            <p className="policyp">
              To maintain the integrity of our service, cloud server service
              that utilizes heavy amounts of system resources may be required to
              be upgraded, or, SstCloud may relocate your site to a different
              server and/or IP address, to reduce the load on the server on
              which you are currently residing.
            </p>
            <h6 className="mt-5">Abusive Clientele:</h6>
            <p className="policyp">
              <b>
                SstCloud may at it's discretion suspend or terminate service of
                any client that is abusive/harassing to SstCloud and/or SstCloud
                employees. Examples of such activity include, but are not
                limited to:
              </b>
            </p>
            <ul>
              <li className="policyp">
                Verbal abuse - cursing, yelling, threatening, etc...
              </li>
              <li className="policyp">
                Ongoing disregard for warnings or notices
              </li>
              <li className="policyp">
                Consistent mistakes/errors/changes causing unnecessary workload
              </li>
              <li className="policyp">
                Spamming SstCloud email addresses, ticket systems, or servers
              </li>
              <li className="policyp">
                Continuous involvement in flood/DDOS activity, hacked services,
                or other ongoing non-permitted usage
              </li>
              <li className="policyp">Consistent late payments or responses</li>
              <li className="policyp">
                Issuing a chargeback or bouncing a payment
              </li>
              <li className="policyp">
                Continuous activation of problematic end-users
              </li>
              <li className="policyp">
                Activity which affects the service(s) of other SstCloud
                clientele
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UsePolicy;
