import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Spinner = ({ path = "/dashboard/admin" }) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1); // Use functional update for count
    }, 1000);

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(interval);
  }, []); // No dependencies so that the effect runs only once when component mounts

  useEffect(() => {
    if (count === 0) {
      navigate(`/${path}`, {
        state: { from: location.pathname },
      });
    }
  }, [count, navigate, location.pathname, path]); // Include count, navigate, location.pathname, and path in the dependencies array

  return (
    <>
      <h1 className="text-center">
        Redirecting you to login page in {count} seconds
      </h1>
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Spinner;
