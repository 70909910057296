import React, { useState, useEffect } from "react";
import "./vps.css";
import vps14 from "../../img/vps14.svg";
import vps15 from "../../img/vps15.svg";
import vps16 from "../../img/vps16.svg";
import vps17 from "../../img/vps17.svg";
import vps18 from "../../img/vps18.svg";
import vps19 from "../../img/vps19.svg";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faIndianRupeeSign,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
// import Modal from "../../component/Modal";
import { useNavigate } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
const LinuxSelfManagedVPS = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/singleplan/self-Managed-Linux-VPS-Servers"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://sstcloud.in:8086/api/query/addquery",
        {
          title: "Fully-Managed-Window-VPS-Servers",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data?.success) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
      } else {
        toast.error("Something went wrong. Please try again.");
      }

      navigate("/");
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <ToastContainer />
      {isMobile ? <Header2 /> : <Header />}
      <div className="homevps marginbotm">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-sm-12">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
              >
                Linux Self-Managed VPS Server - New Delhi, India
              </p>
              <h1 style={{ color: "rgb(230,57,63)" }}>
                Lightning fast NVMe VPS Servers
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                High Performance Virtual Machines
              </p>
              <ul>
                <li>Free Acronis Backup</li>
                <li>Full Root Access</li>
                <li>Self Managed & On-Demand Scaling</li>
                <li>Latest AMD EPYC Servers</li>
              </ul>
              <button
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                style={{
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-3">
          <div className="col-sm-12">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              Self-Managed Linux VPS Server
            </h6>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Boost your website with the best <b>SSTCLOUD</b> VPS hosting
              services. Here is the end of your search for VPS hosting services.
              Now, customers may have super-level access to that operating
              system. Make a Multi-tenant environment with the Powerful VPS
              Server Hosting.
            </p>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Cloud Server or VPS (also known as VDS or Virtual Dedicated
              server) is an isolated environment on a physical server running
              its own operating system and software stack. Each Cloud Server is
              allocated its own CPU, RAM, SSD/NVMe, Network speed and IP
              address(es). Cloud Server offers the speed and performance of
              dedicated hardware and the Scalability of shared hosting at an
              affordable price.s
            </p>
          </div>
        </div>
        <hr />
      </div>
      <div id="start" className="container text-center">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "#313131",
              }}
            >
              SELF-MANAGED LINUX CLOUD SERVERS
            </h1>
          </div>
        </div>
        <div className="row text-center">
          {plan.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 p-3">
                <div className="slide">
                  <div>
                    <h4 style={{ color: "rgb(230,57,63)", fontWeight: "700" }}>
                      {e.type}
                    </h4>

                    <p style={{ fontWeight: "600", fontSize: "18px" }}>
                      <FontAwesomeIcon icon={faIndianRupeeSign} /> {e.price} +
                      GST PM
                    </p>
                    <p>{e.p}</p>
                  </div>
                  <hr
                    style={{
                      width: "100%",
                    }}
                  />
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p1} GB RAM
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p2} vCPUs
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p3} GB SSD
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p4} Bandwidth
                  </p>
                  <button onClick={() => handleShow(e)} className="querybtn2">
                    Configure Now
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="http://localhost:3000/static/media/logo3.d16e8b77734d74fa9567.png"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6 className="text-light">Linux-Shared-Hosting</h6>
                  <button onClick={handleClose} className="querybtn">
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
                <Form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </Form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ADVANCED FEATURES
            </h1>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b">
            <img
              src={vps14}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              NVMe Servers
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b ">
            <img
              src={vps15}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Highly scalable
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bb b">
            <img
              src={vps16}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              24/7 support
            </h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={vps17}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Free Acronis Backup
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={vps18}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Instant upgrades
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col b">
            <img
              src={vps19}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              AMD EPYC Servers
            </h3>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              src="https://leapswitch.com/img/backup.svg"
              alt="img"
              className="managedimg"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1
              style={{
                fontSize: "41px",
                lineHeight: "46px",
                fontWeight: "600",
                color: "#313131",
              }}
            >
              Acronis Backups
            </h1>
            <p
              style={{
                fontSize: "25px",
                lineHeight: "32px",
                color: "#7C7878",
                fontWeight: "600",
              }}
            >
              Acronis Backups Protect your VM with reliable, easy-to-use, secure
              backup solution
            </p>
            <ul
              style={{
                fontSize: "19px",
                lineHeight: "18px",
                fontWeight: "500",
                color: "#7C7878",
              }}
            >
              <li>Antiransomware protected solution</li>
              <li>RAID protected Backups</li>
              <li>Data Loss Prevention</li>
              <li>Alternate day Backups</li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="container my-5">
        <div className="row my-5">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              ALL PLANS INCLUDE
            </p>
          </div>
        </div>
        <div className="row tick">
          <div className="col-md-4 col-sm-12 ">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              IP Address 1
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Control Panel Optional & Paid
            </p>

            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              24/7 Support via Live Chat, Phone and helpdesk
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              OS Windows
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              1 Weekly snapshot backup copy included (Overwrite basis)
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
                MySQL/MS SQL Express Edition
              </div>
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Antivirus: Default with control Panel or optional (paid AV)
            </p>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row close text-center">
          <div className="col-md-6 col-sm-12 py-5">
            <p>We can give you more power</p>
            <p>and resources</p>
            <button
              onClick={() => {
                navigate("/dedicated");
              }}
            >
              Dedicated Servers
            </button>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <p>Need Lighting-fast Self managed</p>
            <p>Linux hosting?</p>

            <button
              onClick={() => {
                navigate("/managed-vps-hosting/linux");
              }}
            >
              Managed Linux Cloud Servers
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid text-center">
        <div className="row linuxbg py-5">
          <div className="col-md-6 col-sm-12">
            <p>Need help? We're always here for you.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <button>Chat with our experts</button>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT KVM CLOUD SERVER.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is KVM ?</h1>
            <p className="hostp">
              KVM is a type of Virtualization used to create Virtual Private
              Servers on a Dedicated Server.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the difference between managed and self-managed Cloud
              Servers?
            </h1>
            <p className="hostp">
              1. <b>Managed Cloud Server :-</b> <br />
              Our company manages all hardware, network, virtualization,
              software, operating system, and control panel issues. The client
              is only responsible for 3rd party applications and coding related
              issues.
            </p>
            <p className="hostp">
              2. <b>Self-Managed Cloud Server :-</b> <br />
              In self-managed, our company is responsible for hardware and
              network support and the virtualization environment, but the client
              will be responsible for installing, configuring and upgrading the
              operating system and other required software.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What kind of support do you provide?</h1>
            <p className="hostp">
              We are providing True 24x7 Support via Phone, Live chat and
              helpdesk support.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Is your server DDOS protected?</h1>
            <p className="hostp">
              Yes, we only provide DDOS protected servers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What about the backup?</h1>
            <p className="hostp">
              Acronis backups are included with Linux managed cloud Server.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide a BGP session with KVM Cloud Servers?
            </h1>
            <p className="hostp">
              Yes, we do provide BGP in Delhi [NCR], India and Asheville, NC,
              USA. BGP has a setup fee of $50 which is a one-time charge.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to purchase additional space along with the cloud
              VPS?
            </h1>
            <p className="hostp">
              Yes, it is possible to purchase additional space along with VPS or
              you can upgrade to higher plan.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to purchase additional bandwidth along with cloud
              VPS?
            </h1>
            <p className="hostp">
              Yes, it is possible to purchase additional bandwidth along with
              VPS or you can upgrade to higher plan.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Could you please share link to check latency?
            </h1>
            <p className="hostp">
              Yes, please check below looking glasses - <br />
              <b>Mumbai</b> - https://lg-mumbai.advancedserverdns.com/
              <br />
              <b>Delhi [NCR]</b> - https://lg-Delhi [NCR].advancedserverdns.com/
              <br />
              <b>Spain</b> - https://lg-spain.advancedserverdns.com/
              <br />
              <b>Portugal</b> - https://lg-portugal.advancedserverdns.com/
              <br />
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LinuxSelfManagedVPS;
