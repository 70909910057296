import React, { useState, useEffect } from "react";
import "./hosting.css";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../component/Footer";
import { useNavigate } from "react-router-dom";

const SharedHosting = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="homehost marginbotm">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-sm-12">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
              >
                Web Hosting
              </p>
              <h1 style={{ color: "rgb(230,57,63)" }}>
                Host Unlimited Domains
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                Seamlessly with our blazing-fast SSD based servers
              </p>
              <ul>
                <li>
                  Fully featured plans with cPanel Control Panel, PHP, MySQL,
                  Plesk, ASP.NET
                </li>
                <li>99.9% uptime guarantee</li>
                <li>Free Migration & Daily Backup</li>
                <li>Multilingual 24 x 7 Support</li>
                <li>30 Days money back guarantee</li>
              </ul>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                onClick={() => {
                  const hostingDiv = document.getElementById("hosting");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row my-3">
          <div id="hosting" className="col-sm-12">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              WHAT IS SHARED HOSTING?
            </h6>
            <p
              style={{
                fontSize: "16px",

                lineHeight: "24px",
              }}
            >
              With SST cloud services, you can enjoy the best web hosting
              services. Are you not satisfied with your web hosting? Then don’t
              worry <b>SSTCLOUD</b> is happy to help you. We offer you a
              complete package within your budget of web hosting with proper
              services. Now, Bring your Business online in minutes with{" "}
              <b>SSTCLOUD</b>. We will provide you a place where you can easily
              host and manage your website.
            </p>
            <p
              style={{
                fontSize: "16px",

                lineHeight: "24px",
              }}
            >
              Shared web hosting is the quickest way to get your website online.
              Be it plain HTML, PHP or ASP.NET we support all web languages. You
              can select Linux Hosting to host HTML/PHP based websites or
              Windows hosting for HTML, PHP or ASP.NET websites. We also offer
              multiple locations for Linux Hosting so that your website is
              closest your visitors' location guaranteeing fastest loading
              speeds. Our Shared Hosting comes with 99.9% or higher guaranteed
              uptime, 24x7x365 Super-Fast Support and a 30 days no conditions
              money back guarantee.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="row plans text-center">
              <div className="col-md-6 col-sm-12 py-5">
                <div>
                  <h3>LINUX HOSTING</h3>
                  <button
                    onClick={() => {
                      navigate("/linux-hosting");
                    }}
                  >
                    VIEW PLAN
                  </button>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />{" "}
                    HTML, PHP, MYSQL
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />{" "}
                    cPanel Control Panel
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />{" "}
                    Email Accounts
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />{" "}
                    Visitor Statistics
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />{" "}
                    Anti-Spam, Malware and Anti-Virus
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />{" "}
                    Starts at just ₹119 / month
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 py-5">
                <h3>WINDOWS HOSTING</h3>
                <button
                  onClick={() => {
                    navigate("/window-hosting");
                  }}
                >
                  VIEW PLAN
                </button>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  ASP.NET 2/3.5/4
                </p>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  PHP 7
                </p>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  .NET core
                </p>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  Latest Plesk Panel
                </p>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  Email Accounts
                </p>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  Visitor Statistics
                </p>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  MySQL 5+ and SQL Server 2012+
                </p>
                <p>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green" }}
                  />{" "}
                  Starts at just ₹180 / month
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12"></div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <div className="row linuxbg py-5">
          <div className="col-md-6 col-sm-12">
            <p>Need help? We're always here for you.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <button>Chat with our experts</button>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT SHARED WEB HOSTING.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is shared hosting?</h1>
            <p className="hostp">
              A shared web hosting service refers to a web hosting service where
              many websites reside on one web server connected to the Internet.
              Each site "sits" on its own partition, or section/place on the
              server, to keep it separate from other sites.
              <br /> As the name suggests, you will be sharing the bandwidth,
              Internet connection and memory of the host machine with other
              customers.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is Linux shared hosting?</h1>
            <p className="hostp">
              A Linux shared hosting is a hosting service suitable to host
              websites that are designed from various computer
              languages/applications developed in PHP such as
              Joomla/Wordpress/Drupal, plain HTML, etc. Linux does not support
              ASP.NET websites. For ASP.NET sites you would have to take our
              Windows shared hosting.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is Windows shared hosting?</h1>
            <p className="hostp">
              Windows shared hosting is a hosting service suitable to host
              websites that are designed from ASP.NET and PHP.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to host multiple websites with Linux/Windows shared
              hosting?
            </h1>
            <p className="hostp">
              Yes, you can host multiple sites on Linux/Windows shared hosting.
              You would have to choose Linux/Windows shared hosting business-2
              or higher plans.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              How many email accounts and databases are you providing with Linux
              shared hosting package?
            </h1>
            <p className="hostp">
              We provide unlimited databases and email accounts with Linux
              shared hosting.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              How many email accounts and databases you are providing with
              Windows shared hosting package?
            </h1>
            <p className="hostp">
              You can create databases as per the package and unlimited email
              accounts. MySQL databases are unlimited. MSSQL Server databases
              are limited to 1 per website. So Business1 will allow 1 database,
              Business 2 will allow 5 databases. MSSQL Server 2008, 2012, 2014
              are supported.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is the Bandwidth based on monthly consumption or yearly
              consumption?
            </h1>
            <p className="hostp">
              The Bandwidth is based on Monthly consumption.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible for me to design a website through Linux shared
              hosting package?
            </h1>
            <p className="hostp">
              Yes, we have already installed Application Installer [
              Installatron ] on our shared node. So you can design your website
              using Wordpress/Joomla/Drupal etc.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Which hosting support for PHP site?</h1>
            <p className="hostp">
              For PHP websites, Linux shared hosting would be the right fit.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Is Tax included in the cost or not?</h1>
            <p className="hostp">
              The pricing you see on the website is excluding Tax. As per
              government norms, 18% GST would be applicable to the cost you see
              on the website.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is SSD?</h1>
            <p className="hostp">
              SSD(Solid State Drive) is a type of hard drive. SSD is much faster
              and more reliable than normal SATA hard drives, as it is based on
              Flash storage and does not have any moving parts.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              If I have purchased the domain from some other vendor and I am
              looking to host a website with you, is it possible?
            </h1>
            <p className="hostp">
              Yes, it is definitely possible to host your website with us even
              though you have purchased your domain from another vendor. You can
              also move your domain to us. Kindly get in touch with our experts
              and they will assist you with the process.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What kind of support do you provide?</h1>
            <p className="hostp">
              We are providing True 24x7 Support via Phone, Live chat and
              helpdesk support.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Is your server DDOS protected?</h1>
            <p className="hostp">
              Yes, we only provide DDOS protected servers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide free SSL with Linux shared hosting?
            </h1>
            <p className="hostp">
              Yes, we do provide LetEncrypt SSL with Linux shared hosting.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is a soft memory?</h1>
            <p className="hostp">
              Soft memory means RAM of the server which is divided by the number
              of clients on the server. The number of accounts/websites vary so
              we limit each client to 1GB of memory so that no single client can
              overload the entire server. We use SSD drives for our servers and
              in Business 1 plan you will get 1.5GB SSD space along with 10GB
              data transfer.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Which hosting supports the asp.net site?</h1>
            <p className="hostp">
              Our Windows shared hosting would be the best fit to host your
              ASP.Net site.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide dedicated IP with Linux shared hosting?
            </h1>
            <p className="hostp">
              Yes, we provide a dedicated IP with shared hosting.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SharedHosting;
