import React, { useState, useEffect } from "react";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";

const PrivicyPolicy = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="container marginbotm">
        <div className="row">
          <div className="col-sm-12">
            <h3
              className="my-2"
              style={{
                fontSize: "25px",
                lineHeight: "30px",
                fontWeight: "600",
                color: "rgb(230,57,63)",
              }}
            >
              THE SstCloud PRIVACY POLICY
            </h3>
            <p className="policyp">
              This privacy policy sets out how SstCloud Networks Pvt Ltd uses
              and protects any information that you give to SstCloud Networks
              Pvt Ltd when you use this website.
            </p>
            <p className="policyp">
              <b>
                SstCloud Networks Pvt Ltd is committed to ensuring that your
                privacy is protected. Should we ask you to provide certain
                information by which you can be identified when using this
                website, then you can be assured that it will only be used in
                accordance with this privacy statement.
              </b>
            </p>
            <p className="policyp">
              <b>
                SstCloud Networks Pvt Ltd may change this policy from time to
                time by updating this page. You should check this page from time
                to time to ensure that you are happy with any changes. This
                policy is effective from 01/04/2011.
              </b>
            </p>
            <h6 className="mt-5">Personal information collection:</h6>
            <p className="policyp">
              <b>What we collect</b>
            </p>
            <ul>
              <li className="policyp">
                We may collect the following information:
              </li>
              <li className="policyp">Name and job title</li>
              <li className="policyp">
                Information about transactions carried out over this website
              </li>
              <li className="policyp">
                Information that you provide for the purpose of subscribing to
                the website services
              </li>
              <li className="policyp">
                Any other information that you send to SstCloud.
              </li>
              <li className="policyp">
                Contact information including email address
              </li>
              <li className="policyp">
                Demographic information such as postcode, preferences and
                interests
              </li>
              <li className="policyp">
                Other information relevant to customer surveys and/or offers
              </li>
            </ul>
            <p>
              <b>What we do with the information we gather</b>
            </p>
            <p>
              <b>
                We require this information to understand your needs and provide
                you with a better service, and in particular for the following
                reasons:
              </b>
            </p>
            <ul>
              <li className="policyp">Administer this website</li>
              <li className="policyp">Personalize the website for you</li>
              <li className="policyp">
                Enable your access to and use of the website services
              </li>
              <li className="policyp">Internal record keeping.</li>
              <li className="policyp">
                We may use the information to improve our products and services.
              </li>
              <li className="policyp">
                Publish information about you on the website
              </li>
              <li className="policyp">
                Send to you products that you purchase
              </li>
              <li className="policyp">
                Supply to you services that you purchase
              </li>
              <li className="policyp">Send you statements and invoices</li>
              <li className="policyp">Collect payments from you; and</li>
              <li className="policyp">Send you marketing communications</li>
            </ul>
            <p className="policyp">
              In addition to the disclosures reasonably necessary for the
              purposes identified elsewhere above, SstCloud may disclose your
              personal information to the extent that it is required to do so by
              law, in connection with any legal proceedings or prospective legal
              proceedings, and in order to establish, exercise or defend its
              legal rights.
            </p>
            <h6 className="mt-5">Security</h6>
            <p className="policyp">
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorised access or disclosure we have put in
              place suitable physical, electronic and managerial procedures to
              safeguard and secure the information we collect online.
            </p>
            <h6 className="mt-5">How do we use Cookies?</h6>
            <p className="policyp">
              A cookie is a small file which asks permission to be placed on
              your computer's hard drive. Once you agree, the file is added and
              the cookie helps analyse web traffic or lets you know when you
              visit a particular site. Cookies allow web applications to respond
              to you as an individual. The web application can tailor its
              operations to your needs, likes and dislikes by gathering and
              remembering information about your preferences. We use traffic log
              cookies to identify which pages are being used. This helps us
              analyse data about webpage traffic and improve our website in
              order to tailor it to customer needs. We only use this information
              for statistical analysis purposes and then the data is removed
              from the system. Overall, cookies help us provide you with a
              better website, by enabling us to monitor which pages you find
              useful and which you do not. A cookie in no way gives us access to
              your computer or any information about you, other than the data
              you choose to share with us. You can choose to accept or decline
              cookies. Most web browsers automatically accept cookies, but you
              can usually modify your browser setting to decline cookies if you
              prefer. This may prevent you from taking full advantage of the
              website. Links to other websites Our website may contain links to
              other websites of interest. However, once you have used these
              links to leave our site, you should note that we do not have any
              control over that other website.
            </p>
            <p className="policyp">
              Therefore, we cannot be responsible for the protection and privacy
              of any information which you provide whilst visiting such sites
              and such sites are not governed by this privacy statement. You
              should exercise caution and look at the privacy statement
              applicable to the website in question. Controlling your personal
              information You may choose to restrict the collection or use of
              your personal information in the following ways: whenever you are
              asked to fill in a form on the website, look for the box that you
              can click to indicate that you do not want the information to be
              used by anybody for direct marketing purposes SstCloud will store
              all the personal information you provide on its secure servers.
            </p>
            <p className="policyp">
              Information relating to electronic transactions entered into via
              this website will be protected by encryption technology.
            </p>
            <p className="policyp">
              If you believe that any information we are holding on you is
              incorrect or incomplete, please write to or email us as soon as
              possible, at the above address. We will promptly correct any
              information found to be incorrect.
            </p>
            <h6 className="mt-5">Cross-border data transfers</h6>
            <p className="policyp">
              Information that SstCloud collects may be stored and processed in
              and transferred between any of the countries in which SstCloud
              operates to enable the use of the information in accordance with
              this privacy policy.
            </p>
            <p className="policyp">
              In addition, personal information that you submit for publication
              on the website will be published on the internet and may be
              available around the world. You agree to such cross-border
              transfers of personal information.
            </p>
            <h6 className="mt-5">Updating this statement</h6>
            <p className="policyp">
              SstCloud may update this privacy policy by posting a new version
              on this website. You should check this page occasionally to ensure
              you are familiar with any changes.
            </p>
            <h6 className="mt-5">Other websites</h6>
            <p className="policyp">
              This website contains links to other websites. SstCloud is not
              responsible for the privacy policies or practices of any third
              party.
            </p>
            <h6 className="mt-5">Disclaimer Policy</h6>
            <p className="policyp">
              The information contained in this website is for general
              information purposes only. The information is provided by SstCloud
              Networks Pvt Ltd and while we endeavour to keep the information up
              to date and correct, we make no representations or warranties of
              any kind, express or implied, about the completeness, accuracy,
              reliability, suitability or availability with respect to the
              website or the information, products, services, or related
              graphics contained on the website for any purpose. Any reliance
              you place on such information is therefore strictly at your own
              risk. In no event will we be liable for any loss or damage
              including without limitation, indirect or consequential loss or
              damage, or any loss or damage whatsoever arising from loss of data
              or profits arising out of, or in connection with, the use of this
              website. Through this website you are able to link to other
              websites which are not under the control of [business name]. We
              have no control over the nature, content and availability of those
              sites. The inclusion of any links does not necessarily imply a
              recommendation or endorse the views expressed within them. Every
              effort is made to keep the website up and running smoothly.
              However, SstCloud Networks Pvt Ltd takes no responsibility for,
              and will not be liable for, the website being temporarily
              unavailable due to technical issues beyond our control.
            </p>
            <h6 className="mt-5">Contacting Us</h6>
            <p className="policyp">
              If there are any questions regarding this privacy policy you may
              contact us using the information below:
            </p>
            <p className="policyp">
              <b>Company Name:</b>SstCloud Networks Pvt Ltd
            </p>
            <p className="policyp">
              <b>Address:</b> 1, Sneh Classics Erandawane Nalstop Pune 411004,
              Maharashtra India
            </p>
            <p className="policyp">
              <b>Telephone No:</b>+91-20-69906990 / +91-20-32670900
            </p>
            <p className="policyp">
              <b>E-Mail ID</b>support@SstCloud.com
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivicyPolicy;
