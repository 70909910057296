import React, { useState, useEffect } from "react";
import "./vps.css";
import vps1 from "../../img/vps1.svg";
import vps2 from "../../img/vps2.svg";
import vps3 from "../../img/vps3.svg";
import vps4 from "../../img/vps4.svg";
import vps5 from "../../img/vps5.svg";
import vps6 from "../../img/vps6.svg";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { faAdd, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const CloudVPS = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    // Initial check on mount
    handleResize();

    // Add event listener to check on resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="homevps marginbotm">
        <div className="container-fluid p-5 cvps">
          <div className="row">
            <div className="col-sm-12">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
              >
                VPS SERVERS
              </p>
              <h1 style={{ color: "rgb(230,57,63)" }}>
                Move to lightning fast Cloud Servers
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                High Performance Virtual Machines
              </p>
              <ul>
                <li>Instant Setup</li>
                <li>Full Root Access</li>
                <li>Self Managed & On-Demand Scaling</li>
                <li>Customized Virtual Machines</li>
                <li>cPanel/WHM, Virtualmin, Plesk Control Panels</li>
              </ul>
              <button
                style={{
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-3">
          <div className="col-sm-12">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              CLOUD SERVER
            </h6>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              If you are searching for the best VPS services, then your search
              has a fruitful end. <b>SSTCLOUD</b> is here to give you full
              support and response. Brighten up your website with our supportive
              services. We are happy to enhance your website and want to see you
              fully satisfied. Avail our dedicated services and high speed
              Today.
            </p>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Cloud Server or VPS (also known as VDS or Virtual Dedicated
              server) is an isolated environment on a physical server running
              its own operating system and software stack. Each Cloud Server is
              allocated its own CPU, RAM, SSD/NVMe, Network speed and IP
              address(es). Cloud Server offers the speed and performance of
              dedicated hardware and the Scalability of shared hosting at an
              affordable price.s
            </p>
          </div>
        </div>
        <hr />
      </div>
      <div id="start" className="container">
        <div className="row text-center">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h3 className="vpsh3">SELF-MANAGED CLOUD SERVERS</h3>
            <h3 className="vpsh3">(LINUX)</h3>
            <button
              className="viewplan"
              onClick={() => {
                navigate("/self-managed-vps-hosting/linux");
              }}
            >
              VIEW PLANS
            </button>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />{" "}
              </div>
              Full Root Access
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              CentOS / Debian / Ubuntu OS
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Virtualizor Control Panel
            </p>

            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              KVM Virtualization
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Latest AMD EPYC Servers
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Starts at just{" "}
              <span style={{ color: "#444444", fontWeight: "bold" }}>
                ₹700/ month
              </span>
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h3 className="vpsh3">SELF-MANAGED CLOUD SERVERS</h3>
            <h3 className="vpsh3">(Windows)</h3>
            <button
              className="viewplan"
              onClick={() => {
                navigate("/self-managed-vps-hosting/window");
              }}
            >
              VIEW PLANS
            </button>
            <div
              style={{
                width: "230px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#6F7173",
                backgroundColor: "#E8E8E8",
                margin: "10px 0",
              }}
            >
              <p style={{ lineHeight: "18px" }}>All Self-Managed Features</p>
              <FontAwesomeIcon icon={faAdd} />
            </div>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />{" "}
              </div>
              Server Optimization
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Firewall Setup
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Scheduled Updates
            </p>

            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Apache , MySQL , PHP installation and configuration.
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Control panel setup
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Starts at just{" "}
              <span style={{ color: "#444444", fontWeight: "bold" }}>
                ₹1999/ month
              </span>
            </p>
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h3 className="vpsh3">MANAGED CLOUD SERVERS</h3>
            <h3 className="vpsh3">(LINUX)</h3>
            <button
              className="viewplan"
              onClick={() => {
                navigate("/managed-vps-hosting/linux");
              }}
            >
              VIEW PLANS
            </button>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />{" "}
              </div>
              Full Administrator Access
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Windows 2016, 2019, 2022
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Virtualizor Control Panel
            </p>

            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Plesk / WebsitePanel
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Free Acronis Backup
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
              </div>
              Starts at just{" "}
              <span style={{ color: "#444444", fontWeight: "bold" }}>
                ₹850/ month
              </span>
            </p>
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h3 className="vpsh3">MANAGED CLOUD SERVERS</h3>
            <h3 className="vpsh3">(WINDOW)</h3>
            <button
              className="viewplan"
              onClick={() => {
                navigate("/managed-vps-hosting/window");
              }}
            >
              VIEW PLANS
            </button>
            <div
              style={{
                width: "230px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#6F7173",
                backgroundColor: "#E8E8E8",
                margin: "10px 0",
              }}
            >
              <p style={{ lineHeight: "18px" }}>All Self-Managed Features</p>
              <FontAwesomeIcon icon={faAdd} />
            </div>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />{" "}
                Scheduled Updates
              </div>
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
                IIS, MSSQL installation and configuration.
              </div>
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
                24/7 Support & Monitoring
              </div>
            </p>

            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
                15 days backup retention
              </div>
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
                Firewall Setup
              </div>
            </p>
            <p className="d-flex planp">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 10px" }}
                />
                Starts at just{" "}
                <span style={{ color: "#444444", fontWeight: "bold" }}>
                  ₹2499/ month
                </span>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ADVANCED FEATURES
            </h1>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b">
            <img
              src={vps1}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Daily backup
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b ">
            <img
              src={vps2}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Private Nameservers
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bb b">
            <img
              src={vps3}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Domain Reseller Panel
            </h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={vps4}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Overselling Enabled
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={vps5}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              SSD Based Servers
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col b">
            <img
              src={vps6}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              cPanel/WHM
            </h3>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <div className="row linuxbg py-5">
          <div className="col-md-6 col-sm-12">
            <p>Need help? We're always here for you.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <button>Chat with our experts</button>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT KVM CLOUD SERVER.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is KVM ?</h1>
            <p className="hostp">
              KVM is a type of Virtualization used to create Virtual Private
              Servers on a Dedicated Server.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the difference between managed and self-managed Cloud
              Servers?
            </h1>
            <p className="hostp">
              1. <b>Managed Cloud Server :-</b> <br />
              Our company manages all hardware, network, virtualization,
              software, operating system, and control panel issues. The client
              is only responsible for 3rd party applications and coding related
              issues.
            </p>
            <p className="hostp">
              2. <b>Self-Managed Cloud Server :-</b> <br />
              In self-managed, our company is responsible for hardware and
              network support and the virtualization environment, but the client
              will be responsible for installing, configuring and upgrading the
              operating system and other required software.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What kind of support do you provide?</h1>
            <p className="hostp">
              We are providing True 24x7 Support via Phone, Live chat and
              helpdesk support.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Is your server DDOS protected?</h1>
            <p className="hostp">
              Yes, we only provide DDOS protected servers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What about the backup?</h1>
            <p className="hostp">
              Acronis backups are included with Linux managed cloud Server.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide a BGP session with KVM Cloud Servers?
            </h1>
            <p className="hostp">
              Yes, we do provide BGP in Delhi [NCR], India and Asheville, NC,
              USA. BGP has a setup fee of $50 which is a one-time charge.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to purchase additional space along with the cloud
              VPS?
            </h1>
            <p className="hostp">
              Yes, it is possible to purchase additional space along with VPS or
              you can upgrade to higher plan.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to purchase additional bandwidth along with cloud
              VPS?
            </h1>
            <p className="hostp">
              Yes, it is possible to purchase additional bandwidth along with
              VPS or you can upgrade to higher plan.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Could you please share link to check latency?
            </h1>
            <p className="hostp">
              Yes, please check below looking glasses - <br />
              <b>Mumbai</b> - https://lg-mumbai.advancedserverdns.com/
              <br />
              <b>Delhi [NCR]</b> - https://lg-Delhi [NCR].advancedserverdns.com/
              <br />
              <b>Spain</b> - https://lg-spain.advancedserverdns.com/
              <br />
              <b>Portugal</b> - https://lg-portugal.advancedserverdns.com/
              <br />
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5"></div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CloudVPS;
