import React, { useState, useEffect } from "react";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import de1 from "../../img/de1.webp";
import de2 from "../../img/de2.webp";
import de3 from "../../img/de3.svg";
import de4 from "../../img/de4.svg";
import de5 from "../../img/de5.svg";
import de6 from "../../img/de6.svg";
import de7 from "../../img/de7.svg";
import de8 from "../../img/de8.svg";
import "./dedicated.css";
import Modal from "../../component/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const SelfManagedDedicatedLinux = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/singleplan/Self-Managed-Windows-dedicated-Servers"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const tableData2 = [
  //   {
  //     Plan: "SDC3-4.30GB",
  //     vCPUs: "4 vCPUs",
  //     CpuFrequency: "≥ 2.8 GHz",
  //     DedicatedRAM: "30 GB",
  //     DiskSpace: "125 GB NVMe SSD",
  //     Monthlyinr1: "₹5,399",
  //   },
  //   {
  //     Plan: "SDC3-8.60GB",
  //     vCPUs: "8 vCPUs",
  //     CpuFrequency: "≥ 2.8 GHz",
  //     DedicatedRAM: "60 GB",
  //     DiskSpace: "250 GB NVMe SSD",
  //     Monthlyinr1: "₹9,460",
  //   },
  //   {
  //     Plan: "SDC3-12.90GB",
  //     vCPUs: "12 vCPUs",
  //     CpuFrequency: "≥ 2.8 GHz",
  //     DedicatedRAM: "90 GB",
  //     DiskSpace: "250 GB NVMe SSD",
  //     Monthlyinr1: "₹13,940",
  //   },
  //   {
  //     Plan: "SDC3-16-120GB",
  //     vCPUs: "16 vCPUs",
  //     CpuFrequency: "≥ 2.8 GHz",
  //     DedicatedRAM: "120 GB",
  //     DiskSpace: "250 GB NVMe SSD",
  //     Monthlyinr1: "₹18,220",
  //   },
  //   {
  //     Plan: "SDC3-24.180GB",
  //     vCPUs: "24 vCPUs",
  //     CpuFrequency: "≥ 2.8 GHz",
  //     DedicatedRAM: "180 GB",
  //     DiskSpace: "250 GB NVMe SSD",
  //     Monthlyinr1: "₹27,080",
  //   },
  //   {
  //     Plan: "SDC3-32.240GB",
  //     vCPUs: "32 vCPUs",
  //     CpuFrequency: "≥ 2.8 GHz",
  //     DedicatedRAM: "240 GB",
  //     DiskSpace: "250 GB NVMe SSD",
  //     Monthlyinr1: "₹35,840",
  //   },
  //   {
  //     Plan: "SDC3-48.360GB",
  //     vCPUs: "48 vCPUs",
  //     CpuFrequency: "≥ 2.8 GHz",
  //     DedicatedRAM: "360 GB",
  //     DiskSpace: "250 GB NVMe SSD",
  //     Monthlyinr1: "₹53,260",
  //   },
  // ];

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="homededi marginbotm">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-sm-12">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
              >
                Self Managed Linux Dedicated Servers
              </p>
              <h1 style={{ color: "rgb(230,57,63)" }}>
                More Power & Control with Dedicated Servers
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                Recommended for
              </p>
              <ul>
                <li>Exclusive Unshared Server Resources</li>
                <li>Fully Customizable Hardware Options</li>
                <li>Inherent User Isolation Security</li>
              </ul>
              <button
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                style={{
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <p
              className="pt-5 text-center"
              style={{
                fontSize: "17px",
                lineHeight: "25px",
                fontWeight: "600",
              }}
            >
              Customer Happiness
            </p>
          </div>
          <div className="col-md-4 col-sm-12 py-4">
            <img
              src={de1}
              alt="img"
              style={{ width: "146px", height: "67px" }}
            />
            <p
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "400",
              }}
            >
              4.6/5 | 73 reviews | Excellent
            </p>
          </div>
          <div className="col-md-4 col-sm-12 py-4">
            <img
              src={de2}
              alt="img"
              style={{ width: "146px", height: "67px" }}
            />
            <p
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "400",
              }}
            >
              4.1/5 | 155 reviews | Excellent
            </p>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-sm-12">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              Dedicated Server
            </h6>
            <p
              style={{
                fontSize: "16px",

                lineHeight: "24px",
              }}
            >
              Make your Website up with <b>SSTCLOUD</b> best services.{" "}
              <b>SSTCLOUD</b> provides you a platform where you can manage and
              host your website. Now, you can Host your website within your
              budget efficiently. We are happy to support you.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="py-5"
              style={{
                color: "rgb(230,57,63)",
                textAlign: "center",
              }}
            >
              Self-Managed Windows Dedicated Servers
            </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table id="start" class="table">
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "rgb(230,57,63)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      scope="col"
                    >
                      Plan
                    </th>
                    <th
                      style={{
                        backgroundColor: "rgb(230,57,63)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      scope="col"
                    >
                      Dedicated vCPUs
                    </th>
                    <th
                      style={{
                        backgroundColor: "rgb(230,57,63)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      scope="col"
                    >
                      CPU Frequency
                    </th>
                    <th
                      style={{
                        backgroundColor: "rgb(230,57,63)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      scope="col"
                    >
                      Dedicated RAM
                    </th>

                    <th
                      style={{
                        backgroundColor: "rgb(230,57,63)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      scope="col"
                    >
                      Disk Space
                    </th>
                    <th
                      style={{
                        backgroundColor: "rgb(230,57,63)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      scope="col"
                    >
                      Price (Billed Monthly)
                    </th>
                    <th
                      style={{
                        backgroundColor: "rgb(230,57,63)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      scope="col"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {plan.map((e, index) => (
                    <tr key={index}>
                      <td className="mapded">{e.type}</td>
                      <td className="mapded">{e.p1}</td>
                      <td className="mapded">{e.p2}</td>
                      <td className="mapded">{e.p3}</td>

                      <td className="mapded">
                        <p>{e.p4}</p>
                      </td>
                      <td className="mapded">
                        <p>{e.price}</p>
                      </td>
                      <td>
                        <Modal name="Configure Now" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ADVANCED FEATURES
            </h1>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b">
            <img
              src={de3}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Grow fast with Performance
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b ">
            <img
              src={de4}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              24/7 Support & Monitoring*
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bb b">
            <img
              src={de5}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Quick deployment
            </h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={de6}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Hardware customization
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={de7}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Initial setup waived off
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col b">
            <img
              src={de8}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Server management*
            </h3>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row my-5">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              ALL PLANS INCLUDE
            </p>
          </div>
        </div>
        <div className="row tick">
          <div className="col-md-4 col-sm-12 ">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Free setup
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              HDD/SSD drives
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Raid Controler
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Full Root / Administrator Access
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              99.9% Uptime Guarantee
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              24/7 Support via Live Chat, Phone and helpdesk*
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Hardware level access via IPMI
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              100/1000 Mbps Port Speed
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid text-center">
        <div className="row linuxbg py-5">
          <div className="col-md-6 col-sm-12">
            <p>Need help? We're always here for you.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <button>Chat with our experts</button>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT DEDICATED SERVERS.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is a Dedicated Server?</h1>
            <p className="hostp">
              A dedicated server is a type of web hosting where the user leases
              an entire physical server from a hosting provider, which is
              exclusively used by the user's website or application. This means
              that the user has complete control over the server's resources,
              including the CPU, RAM, storage, and bandwidth.
            </p>
            <p className="hostp">
              They offer several advantages over other types of hosting, such as
              shared hosting or virtual private servers (VPS), including:
              Performance, Security, Customization and Reliability.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the difference between Self-Managed and Managed Hosting?
            </h1>
            <p className="hostp">
              1. <b>Managed Server :-</b> <br />
              Our company manages all hardware, network, virtualization,
              software, operating system, and control panel issues. The client
              is only responsible for 3rd party applications and coding related
              issues.
            </p>
            <p className="hostp">
              2. <b>Self-Managed Server :-</b> <br />
              In self-managed, our company is responsible for hardware and
              network support and the virtualization environment, but the client
              will be responsible for installing, configuring and upgrading the
              operating system and other required software.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What are the Operating System (OS) options available with
              Dedicated server?
            </h1>
            <p className="hostp">
              Operating systems available for dedicated servers are as follows:
              Linux: CentOS 8.x, Ubuntu 22.x, Debian 11.x, Proxmox 7.x,
              AlmaLinux 9.x, CloudLinux 8.x and others. Windows: Windows Server
              2016, 2019, 2022 and others. Others: Custom OS.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is there a control panel available for managing Dedicated Servers?
            </h1>
            <p className="hostp">
              Our servers come with a choice of using cPanel & DirectAdmin
              (Linux) and Plesk (Windows and Linux) control panels. It allows
              users to easily manage their servers and perform various tasks,
              such as configuring server settings, installing software,
              monitoring server performance, and managing user accounts.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              How long will my server take to be activated?
            </h1>
            <p className="hostp">
              Single CPU with default hardware configuration we aim to activate
              within 12 hours. For Dual CPU configurations, expected setup time
              varies from 1-3 days. Please contact sales members of our team and
              will endeavor to provide an ETA.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Can I upgrade my Dedicated Server in future?
            </h1>
            <p className="hostp">
              We offer highly customizable and flexible Dedicated Servers. We
              will transfer all data to Dedicated Server without any downtime
              and outage.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you offer RAID with your Dedicated servers?
            </h1>
            <p className="hostp">
              It is a standard inclusion with all our Dedicated servers.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the network uptime you provide on Dedicated Server?
            </h1>
            <p className="hostp">
              We provide 99.9% network uptime guarantee SLA.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you offer any additional services or add-ons for your Dedicated
              servers?
            </h1>
            <p className="hostp">
              Indeed, we provide a range of add-ons and additional services for
              our dedicated servers, like managed services, Acronis backup
              options, and custom configurations.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide a BGP session with a Dedicated server?
            </h1>
            <p className="hostp">
              Yes, we do offer Dedicated Servers with BGP Connection. We charge
              $50 one time setup fee for BGP. In both Mumbai DCs we can offer
              Internet Exchange routes ( around 35000 ) from DeCIX, NIXI and
              ExtremeIX.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SelfManagedDedicatedLinux;
