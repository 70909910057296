import React, { useState, useEffect } from "react";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import de1 from "../../img/de1.webp";
import de2 from "../../img/de2.webp";
import de3 from "../../img/de3.svg";
import de4 from "../../img/de4.svg";
import de5 from "../../img/de5.svg";
import de6 from "../../img/de6.svg";
import de7 from "../../img/de7.svg";
import de8 from "../../img/de8.svg";
import "./dedicated.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Dedicated = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const review = [
    {
      p: "<b>SSTCLOUD</b> Network Pvt Ltd has been providing me with all of my website hosting needs, and I couldn't be more pleased with their assistance. Their servers' dependability and speed are impressive, ensuring that my site is constantly operational and responsive. The customer service is excellent and always responds quickly to my questions. My hosting settings can be managed with ease thanks to the user-friendly, intuitive interface. For anyone looking for a dependable hosting solution, I wholeheartedly endorse <b>SSTCLOUD</b> Network Pvt Ltd.",
      name: "Dharmendra Sorout",
    },
    {
      p: "I am glad to share my exceptional experience with <b>SSTCLOUD</b> for their outstanding support and technical expertise in configuring our cloud infrastructure. The journey was made incredibly smooth, all thanks to the remarkable efforts of Mr. Akar Periwal and Mr. Pravin Dodtale, whose profound knowledge, and skills in designing cloud infrastructure left me truly impressed. I would also like to extend my gratitude to the Mr. Ishan Talathi - CEO of <b>SSTCLOUD</b> for fostering a culture of excellence that reflects in every interaction and solution provided by the team. The CEO's unwavering support across all parameters further solidified my trust in their services.",
      name: "Ompal Gaur",
    },
    {
      p: `I recently had the pleasure of utilizing the services of <b>SSTCLOUD</b>, and I must say that my experience has been nothing short of exceptional. Fast servers, exemplary support, and overall excellence in web hosting services. If you're looking for a hosting provider that delivers top-notch performance and support, <b>SSTCLOUD</b> is the way to go. Suraj responded quickly to my question regarding billing and sales
        `,
      name: "Anjali Kiran",
    },
    {
      p: `I am in hosting industry as security consultant for more than a decade and dealing with big data centers for the requirements of my customers. But it was lacking Indian Servers with super low cost prices.
        Good support team - <b>SSTCLOUD</b> successfully mate my requirements. Also - went ahead of their SLA to support my need when required. Kudos. Also - like to mention sales guy Swapnil to help me on all aspect for getting priority support.
        `,
      name: "Priya Singh",
    },
    {
      p: `I've had an excellent experience with this Hosting Provider. Pravin D provided outstanding support for server setup and prefix management. I highly recommend this Hosting Provider to others for their hosting needs.`,
      name: "Rahul",
    },
    {
      p: `Its been more than a year now, I am buying multiple bare metal dedicated servers from <b>SSTCLOUD</b> for our IT operation requirements, They are highly professional and rapid tech support enabled our IT operation smooth and running 24x7. We are using India location and having recorded 99.99% uptime as described in their SLA. As we are dealing with technology there are some chances that time services went down without prior notification but I really appreciate the reactive support, quick bug fixing, highly trained technical staff make <b>SSTCLOUD</b> a phenomenal hosting provider in India.`,
      name: "Hardik",
    },
  ];

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="homededi marginbotm">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-sm-12">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
              >
                Dedicated Servers
              </p>
              <h1 style={{ color: "rgb(230,57,63)" }}>
                More Power & Control with Dedicated Servers
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                Recommended for
              </p>
              <ul>
                <li>Exclusive Unshared Server Resources</li>
                <li>Fully Customizable Hardware Options</li>
                <li>Inherent User Isolation Security</li>
              </ul>
              <button
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                style={{
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <p
              className="pt-5 text-center"
              style={{
                fontSize: "17px",
                lineHeight: "25px",
                fontWeight: "600",
              }}
            >
              Customer Happiness
            </p>
          </div>
          <div className="col-md-4 col-sm-12 py-4">
            <img
              src={de1}
              alt="img"
              style={{ width: "146px", height: "67px" }}
            />
            <p
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "400",
              }}
            >
              4.6/5 | 73 reviews | Excellent
            </p>
          </div>
          <div className="col-md-4 col-sm-12 py-4">
            <img
              src={de2}
              alt="img"
              style={{ width: "146px", height: "67px" }}
            />
            <p
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "400",
              }}
            >
              4.1/5 | 155 reviews | Excellent
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row my-5">
          <div class="col-sm-12 text-center">
            <h1
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
              }}
            >
              DEDICATED SERVER HOSTING
            </h1>
            <p
              style={{ fontSize: "18px", lineHeight: "24px", color: "#6F7173" }}
            >
              Make your Website up with <b>SSTCLOUD</b> best services.{" "}
              <b>SSTCLOUD</b> provides you a platform where you can manage and
              host your website. Now, you can Host your website within your
              budget efficiently. We are happy to support you.
            </p>
            <p
              style={{ fontSize: "18px", lineHeight: "24px", color: "#6F7173" }}
            >
              <b>SSTCLOUD</b> Networks offers fully managed and self-managed
              dedicated servers across the globe with the latest Intel/AMD
              processors and premium Tier 1 bandwidth. All our servers come with
              RAID capability for data security and IPMI for out-of-band
              connectivity. We have servers up to 256 cores, 2TB RAM, and 24
              HDD, SSD, and U.3 NVMe drives capacity. Please contact us if you
              do not find the configuration that you are looking for. Our 24x7
              support team is always here to assist you.
            </p>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ADVANCED FEATURES
            </h1>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b">
            <img
              src={de3}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Grow fast with Performance
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b ">
            <img
              src={de4}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              24/7 Support & Monitoring*
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bb b">
            <img
              src={de5}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Quick deployment
            </h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={de6}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Hardware customization
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={de7}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Initial setup waived off
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col b">
            <img
              src={de8}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Server management*
            </h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          {review.map((e, index) => (
            <div key={index} className="col-md-4 col-sm-12 my-4 text-center">
              <div style={{ width: "300px" }}>
                <p
                  className="mapdedp"
                  style={{
                    height: "170px",
                    overflow: "auto",
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: "300",
                    fontFamily: "Montserrat, sans-serif",
                    color: "#313131",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#b30000 #f5f5f5",
                  }}
                >
                  {e.p}
                </p>
                <div>
                  <FontAwesomeIcon style={{ color: "orange" }} icon={faStar} />
                  <FontAwesomeIcon style={{ color: "orange" }} icon={faStar} />
                  <FontAwesomeIcon style={{ color: "orange" }} icon={faStar} />
                  <FontAwesomeIcon style={{ color: "orange" }} icon={faStar} />
                  <FontAwesomeIcon style={{ color: "orange" }} icon={faStar} />
                </div>
                <h3
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    color: "#313131",
                  }}
                >
                  {e.name}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container my-5">
        <div className="row close text-center">
          <div className="col-md-6 col-sm-12 py-5">
            <p>Solutions for</p>
            <p>High Traffic websites</p>
            <button
              onClick={() => {
                navigate("/linux-hosting");
              }}
            >
              Managed Linux Cloud Server
            </button>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <p>Hosting PHP websites</p>
            <p>for your customers?</p>
            <button
              onClick={() => {
                navigate("/linux-reseller");
              }}
            >
              Reseller Linux Web Hosting
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <div className="row linuxbg py-5">
          <div className="col-md-6 col-sm-12">
            <p>Need help? We're always here for you.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <button>Chat with our experts</button>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT DEDICATED SERVERS.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is a Dedicated Server?</h1>
            <p className="hostp">
              A dedicated server is a type of web hosting where the user leases
              an entire physical server from a hosting provider, which is
              exclusively used by the user's website or application. This means
              that the user has complete control over the server's resources,
              including the CPU, RAM, storage, and bandwidth.
            </p>
            <p className="hostp">
              They offer several advantages over other types of hosting, such as
              shared hosting or virtual private servers (VPS), including:
              Performance, Security, Customization and Reliability.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the difference between Self-Managed and Managed Hosting?
            </h1>
            <p className="hostp">
              1. <b>Managed Server :-</b> <br />
              Our company manages all hardware, network, virtualization,
              software, operating system, and control panel issues. The client
              is only responsible for 3rd party applications and coding related
              issues.
            </p>
            <p className="hostp">
              2. <b>Self-Managed Server :-</b> <br />
              In self-managed, our company is responsible for hardware and
              network support and the virtualization environment, but the client
              will be responsible for installing, configuring and upgrading the
              operating system and other required software.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What are the Operating System (OS) options available with
              Dedicated server?
            </h1>
            <p className="hostp">
              Operating systems available for dedicated servers are as follows:
              Linux: CentOS 8.x, Ubuntu 22.x, Debian 11.x, Proxmox 7.x,
              AlmaLinux 9.x, CloudLinux 8.x and others. Windows: Windows Server
              2016, 2019, 2022 and others. Others: Custom OS.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is there a control panel available for managing Dedicated Servers?
            </h1>
            <p className="hostp">
              Our servers come with a choice of using cPanel & DirectAdmin
              (Linux) and Plesk (Windows and Linux) control panels. It allows
              users to easily manage their servers and perform various tasks,
              such as configuring server settings, installing software,
              monitoring server performance, and managing user accounts.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              How long will my server take to be activated?
            </h1>
            <p className="hostp">
              Single CPU with default hardware configuration we aim to activate
              within 12 hours. For Dual CPU configurations, expected setup time
              varies from 1-3 days. Please contact sales members of our team and
              will endeavor to provide an ETA.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Can I upgrade my Dedicated Server in future?
            </h1>
            <p className="hostp">
              We offer highly customizable and flexible Dedicated Servers. We
              will transfer all data to Dedicated Server without any downtime
              and outage.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you offer RAID with your Dedicated servers?
            </h1>
            <p className="hostp">
              It is a standard inclusion with all our Dedicated servers.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the network uptime you provide on Dedicated Server?
            </h1>
            <p className="hostp">
              We provide 99.9% network uptime guarantee SLA.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you offer any additional services or add-ons for your Dedicated
              servers?
            </h1>
            <p className="hostp">
              Indeed, we provide a range of add-ons and additional services for
              our dedicated servers, like managed services, Acronis backup
              options, and custom configurations.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide a BGP session with a Dedicated server?
            </h1>
            <p className="hostp">
              Yes, we do offer Dedicated Servers with BGP Connection. We charge
              $50 one time setup fee for BGP. In both Mumbai DCs we can offer
              Internet Exchange routes ( around 35000 ) from DeCIX, NIXI and
              ExtremeIX.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Dedicated;
