import React from "react";
import { NavLink, Link } from "react-router-dom";
import sstcloudlogo from "../img/sstcloudlogo.png";
import head1 from "../img/head1.jpg";
import head2 from "../img/head2.jpg";
import head3 from "../img/head3.jpg";
import head4 from "../img/head4.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import "./header.css";
// import axios from "axios";
import Modal from "./Modal";
import { useAuth } from "../component/auth/Auth";

import useScrollDirection from "./Scroll";

const Header = () => {
  const { hideNavbar } = useScrollDirection();
  const [auth, setAuth] = useAuth();

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };
  // const handleDialClick = () => {
  //   // Replace '1234567890' with the desired phone number
  //   window.location.href = "tel:9015937199";
  // };
  const handleWhatsAppRedirect = () => {
    // Replace the 'phone_number' below with the actual phone number you want to message
    const phoneNumber = "9015937199";
    const message = encodeURIComponent("Hello, I want to get started!");

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open WhatsApp in a new tab
    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      <div className={`headermaindiv`} style={{ overflow: "hidden" }}>
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" style={{ textAlign: "right" }}>
              <p>login</p>
            </div>
          </div>
        </div> */}
        <nav className="navbar navbar-expand-lg bg-body-tertiary ">
          <div className="container-fluid ">
            <NavLink className="navbar-brand px-4" to="/">
              <img
                style={{
                  height: "80px",
                  width: "200px",
                  padding: "0",
                  margin: "0",
                }}
                src={sstcloudlogo}
                alt="logo"
              />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav ms-auto px-5 mb-2 mb-lg-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <li className="nav-item mx-2 my-0">
                  <NavLink className="nav-link " aria-current="page">
                    24x7 Support : +91 90159 37199
                  </NavLink>
                </li>
                <li className="nav-item mx-2 my-0">
                  <NavLink className="nav-link " aria-current="page" to="/">
                    Sales : +91 90159 37199
                  </NavLink>
                </li>
                <li className="nav-item mx-2 my-0">
                  <NavLink className="nav-link " aria-current="page" to="/">
                    {!auth.user ? (
                      <li style={{ listStyle: "none" }} className="topnav1">
                        <div>
                          <div>
                            <Link
                              type="button"
                              style={{ textDecoration: "none", color: "black" }}
                              to="/login"
                            >
                              Login
                            </Link>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li
                        className="topnav1 dropdown"
                        style={{ listStyle: "none" }}
                      >
                        <div className="dropdown" style={{ zIndex: "1000" }}>
                          <Link
                            className="nav-link dropdown-toggle"
                            role="button"
                            id="navbarDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {auth?.user?.name}
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                            style={{ zIndex: "100000" }}
                          >
                            <li>
                              <NavLink
                                to={"/dashboard/admin"}
                                className="dropdown-item  bg-light"
                              >
                                Dashboard
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown-item bg-light text-dark"
                                onClick={logout}
                                to="/"
                              >
                                Logout
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )}
                  </NavLink>
                </li>
                <li className="nav-item mx-2 my-0"></li>
                {/* <li className="nav-item mx-2 my-0">
                  <NavLink className="nav-link " aria-current="page" to="/">
                    Login
                  </NavLink>
                </li> */}
                <li className="nav-item mx-2 my-0">
                  <NavLink className="nav-link ">
                    <Modal name="Request Your Free Quote" />
                  </NavLink>
                </li>
                <li className="nav-item mx-2 my-0">
                  <NavLink
                    style={{
                      backgroundColor: "lightgreen",
                      color: "white",
                      height: "30px",
                      width: "30px",
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                    }}
                    className="nav-link "
                    aria-current="page"
                    onClick={handleWhatsAppRedirect}
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </NavLink>
                </li>{" "}
              </ul>
            </div>
          </div>
        </nav>
        <div className="container-fluid menus">
          <div className="row">
            <div className="col-sm-12">
              <ul className="headul">
                <li>
                  <NavLink className="navli items text-light" to="/">
                    HOME
                  </NavLink>
                </li>
                <li className="navli dropp">
                  <NavLink
                    className="navli items text-light"
                    to="/shared-hosting"
                  >
                    {" "}
                    HOSTING
                    <FontAwesomeIcon icon={faCaretDown} />
                  </NavLink>
                  <div className="container-fluid p-5 drop">
                    <div className="row">
                      <div className="col-lg-4">
                        <h4 className="py-5" style={{ fontWeight: "400" }}>
                          <NavLink
                            style={{
                              color: "rgb(230,57,63)",
                              textDecoration: "none",
                            }}
                          >
                            {" "}
                            Shared Hosting
                          </NavLink>
                        </h4>
                        <p>
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink className="navli" to="/linux-hosting">
                              {" "}
                              Linux Hosting{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Fully Featured Linux Plans with cPanel , Perl , PHP
                            and more
                          </p>
                        </p>
                        <p className="py-5">
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink className="navli" to="/window-hosting">
                              {" "}
                              Windows Hosting{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Fully Featured Windows Hosting Plans with Plesk ,
                            ASP.Net and more
                          </p>
                        </p>
                      </div>
                      <div className="col-lg-4">
                        <h4 className="py-5" style={{ fontWeight: "400" }}>
                          Reseller Hosting
                        </h4>
                        <p>
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink className="navli" to="/linux-reseller">
                              {" "}
                              Linux Reseller Hosting{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                            <span
                              style={{
                                fontSize: "10px",
                                backgroundColor: "rgb(230,57,63)",
                                color: "white",
                                padding: "3px 5px",
                                borderRadius: "5px",
                              }}
                            >
                              SAVE UPTO 43%
                            </span>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Start Your Hosting Business with cPanel/WHM Today
                          </p>
                        </p>
                        <p className="py-5">
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink className="navli" to="/window-reseller">
                              {" "}
                              Windows Reseller Hosting{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Start Your Hosting Business with Plesk panel Today
                          </p>
                        </p>
                      </div>
                      <div className="col-lg-4 text-center">
                        <img
                          className="py-4"
                          style={{ height: "250px", padding: "10px 20px" }}
                          src={head1}
                          alt="host"
                        />
                        <p style={{ color: "gray" }}>
                          Start selling shared hosting services by using the
                          reseller hosting packages
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="navli dropp2  items">
                  <NavLink
                    to="/cloudvps"
                    className="navli"
                    style={{ color: "white" }}
                  >
                    {" "}
                    VIRTUAL PRIVATE SERVER{" "}
                    <FontAwesomeIcon icon={faCaretDown} />
                  </NavLink>
                  <div className="container-fluid p-5 drop2">
                    <div className="row">
                      <div className="col-lg-4">
                        <h4 className="py-5" style={{ fontWeight: "400" }}>
                          Fully Managed VPS Servers
                        </h4>
                        <p>
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/managed-vps-hosting/linux"
                              className="navli"
                            >
                              {" "}
                              Fully Managed Linux VPS Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Fully managed linux VPS servers
                          </p>
                        </p>
                        <p className="py-4">
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/managed-vps-hosting/window"
                              className="navli"
                            >
                              {" "}
                              Fully Managed Windows VPS Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Fully managed Windows VPS servers
                          </p>
                        </p>
                      </div>
                      <div className="col-lg-4">
                        <h4 className="py-5" style={{ fontWeight: "400" }}>
                          Self-Managed VPS Servers
                        </h4>
                        <p>
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/self-managed-vps-hosting/linux"
                              className="navli"
                            >
                              {" "}
                              Self-Managed Linux VPS Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Self-managed linux VPS servers with root access
                          </p>
                        </p>
                        <p className="py-4">
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/self-managed-vps-hosting/window"
                              className="navli"
                            >
                              {" "}
                              Self-Managed Windows VPS Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Start Your Hosting Business with Plesk panel Today
                          </p>
                        </p>
                      </div>
                      <div className="col-lg-4 text-center">
                        <img
                          className="py-4"
                          style={{ height: "250px", padding: "10px 20px" }}
                          src={head2}
                          alt="host"
                        />
                        <p style={{ color: "gray" }}>
                          Enjoy faster performance and stability with our
                          Virtual Private Server
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="navli items text-light dropp3">
                  <NavLink
                    className="navli"
                    to="/dedicated"
                    style={{ color: "white" }}
                  >
                    DEDICATED SERVER
                    <FontAwesomeIcon icon={faCaretDown} />
                  </NavLink>
                  <div className="container-fluid p-5 drop3">
                    <div className="row">
                      <div className="col-lg-4">
                        <h4 className="" style={{ fontWeight: "400" }}>
                          Fully Managed Dedicated Servers
                        </h4>
                        <p>
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/fully-managed-dedicated-servers/linux"
                              className="navli"
                            >
                              {" "}
                              Fully Managed Linux Dedicated Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Fully managed linux Dedicated servers
                          </p>
                        </p>
                        <p className="py-4">
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/fully-managed-dedicated-servers/windows"
                              className="navli"
                            >
                              {" "}
                              Fully Managed Windows Dedicated Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Fully managed Windows Dedicated servers
                          </p>
                        </p>
                      </div>
                      <div className="col-lg-4">
                        <h4 className="" style={{ fontWeight: "400" }}>
                          Self Managed Dedicated Servers
                        </h4>
                        <p>
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/self-managed-dedicated-servers/linux"
                              className="navli"
                            >
                              {" "}
                              Self Managed Linux Dedicated Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Self managed linux Dedicated servers
                          </p>
                        </p>
                        <p className="py-4">
                          <h6
                            style={{
                              color: "rgb(230,57,63)",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            <NavLink
                              to="/self-managed-dedicated-servers/windows"
                              className="navli"
                            >
                              {" "}
                              Self Managed Windows Dedicated Servers{" "}
                              <FontAwesomeIcon icon={faCaretRight} />
                            </NavLink>
                          </h6>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          >
                            Self managed Windows Dedicated servers
                          </p>
                        </p>
                      </div>
                      <div className="col-lg-4 text-center">
                        <img
                          className="py-4"
                          style={{ height: "250px", padding: "10px 20px" }}
                          src={head3}
                          alt="host"
                        />
                        <p style={{ color: "gray" }}>
                          Enhance your own and your customers' growth by
                          providing more flexibility, scalability, and better
                          management.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <NavLink className="navli items text-light" to="/gpu">
                    GPU SERVER
                  </NavLink>
                </li>
                <li className="navli items text-light dropp4">
                  <NavLink
                    className="navli"
                    to="/security-ssl"
                    style={{ color: "white" }}
                  >
                    SECURITY
                    <FontAwesomeIcon icon={faCaretDown} />
                  </NavLink>
                  <div className="container-fluid p-5 drop4">
                    <div className="row">
                      <div className="col-lg-4">
                        <h4
                          style={{
                            color: "rgb(230,57,63)",
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginTop: "110px",
                          }}
                        >
                          <NavLink to="/security-ssl" className="navli">
                            {" "}
                            SSL <FontAwesomeIcon icon={faCaretRight} />
                          </NavLink>
                        </h4>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          Secure your website with https.
                        </p>
                        <h4
                          style={{
                            color: "rgb(230,57,63)",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          <NavLink to="/security-backup" className="navli">
                            {" "}
                            Backup <FontAwesomeIcon icon={faCaretRight} />
                          </NavLink>
                        </h4>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          Ransomware protected Cloud Backup solution.
                        </p>
                      </div>
                      <div className="col-lg-4 text-center">
                        <img
                          className="py-4"
                          style={{ height: "250px", padding: "10px 20px" }}
                          src={head4}
                          alt="host"
                        />
                        <p style={{ color: "gray" }}>
                          Security is no longer optional. Secure your website
                          today!
                        </p>
                      </div>
                      <div className="col-lg-4"></div>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <NavLink className="navli items text-light" to="/">
                    <span>HIRING</span>
                  </NavLink>
                </li> */}
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
