import React, { useState, useEffect } from "react";
import "./ssl.css";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../component/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SSL = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/singleplan/SSL"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    // Initial check on mount
    handleResize();

    // Add event listener to check on resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="home2 marginbotm ">
        <div className="container-fluid p-5 ">
          <div className="row">
            <div className="col-md-6 col-sm-12"></div>
            <div className="col-md-6 col-sm-12 text-black">
              <h1
                style={{
                  color: "rgb(230,57,63)",
                  fontWeight: "600",
                  fontSize: "41px",
                  lineHeight: "46px",
                  textAlign: "left",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Get the green address bar
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                Secure your website with SSL Certificates
              </p>
              <ul>
                <li>Provides greater trust and authenticity</li>
                <li>Recommended for Business Use</li>
                <li>Universal device and browser compatibility</li>
              </ul>
              <button
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                style={{
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-3">
          <div className="col-sm-12">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              SSL CERTIFICATES
            </h6>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Security of your website / application is our at-most priority. As
              a part of our offering we also provide solution like SSL, SiteLock
              and Anti-malware based cloud backup solution to ensure you are
              always protected from prying eyes.
            </p>
          </div>
          <hr />
        </div>
      </div>
      <div className="container text-center">
        <div id="start" className="row my-5">
          <div className="col-sm-12 text-center">
            <h1
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
              }}
            >
              CHOOSE SSL, AS PER YOUR REQUIREMENT
            </h1>
          </div>
        </div>
        <div className="row">
          {plan.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
                <div className="manageddiv">
                  <h5 className="managedh">{e.type}</h5>
                  <p style={{ fontSize: "20px", fontWeight: "600" }}>
                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                    {e.price} per year
                  </p>
                  <Modal name="Configure Now" />
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p1}
                  </p>
                  <p className="managedmp">
                    <FontAwesomeIcon icon={faCheck} /> {e.p2}
                  </p>
                  <p className="managedmp">
                    <FontAwesomeIcon icon={faCheck} /> {e.p3}
                  </p>
                  <p className="managedmp">
                    <FontAwesomeIcon icon={faCheck} /> {e.p4}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container my-5">
        <div className="row my-5">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              ALL PLANS INCLUDE
            </p>
          </div>
        </div>
        <div className="row tick">
          <div className="col-md-4 col-sm-12 ">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              256-Bit Encryption
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Free Reissues
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Site Seal
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Browser Support 99.9%
            </p>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row close text-center">
          <div className="col-md-6 col-sm-12 py-5">
            <p>Backup your servers to the Cloud</p>

            <button
              onClick={() => {
                navigate("/security-backup");
              }}
            >
              Cloud Backup
            </button>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <p>Protect your website from Hackers and Malware</p>
            {/* <p>Linux hosting?</p> */}

            <button
              onClick={() => {
                navigate("/security-backup");
              }}
            >
              SiteLock
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SSL;
