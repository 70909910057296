import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sstcloudlogo from "../img/sstcloudlogo.png";

const ModalComponent = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    option: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sstcloud.in:8086/api/quote/newquote",
        formData
      );
      toast.success(response?.data?.message);
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Oops! Something went wrong. Please try again later.");
    }
  };

  return (
    <>
      <ToastContainer />
      <button type="button" className="btn modalbtn" onClick={handleShow}>
        Request Your Free Quote
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={sstcloudlogo}
              alt="img"
              style={{ width: "100px", height: "70px" }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter Number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="option">
              <Form.Label>Select a Service</Form.Label>
              <Form.Select
                name="option"
                value={formData.option}
                onChange={handleChange}
                required
              >
                <option value="">Choose...</option>
                <option value="Hosting Server">Hosting Server</option>
                <option value="Virtual Private Server">
                  Virtual Private Server
                </option>
                <option value="Dedicated Server">Dedicated Server</option>
                <option value="GPU server">GPU Server</option>
                <option value="Security">Security</option>
              </Form.Select>
            </Form.Group>
            <Button onClick={handleSubmit} variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
