import React, { useState, useEffect } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../img/sstcloudlogo.png";
import axios from "axios";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Modal,
  TextField,
  InputLabel,
  Select,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AllUsers = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [users, setUsers] = useState([]);

  const [showSideNav2, setShowSideNav2] = useState(false); // State to control SideNav2 visibility

  const getAllUsers = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/user/allusers"
      );
      setUsers(data.users);
      console.log(data.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true);
        setDrawerOpen(false);
      } else {
        setShowSideNav2(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);

    //eslint-disable-next-line
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleCloses = () => setOpen(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    post: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      const response = await axios.post(
        "https://sstcloud.in:8086/api/user/newuser",
        formData
      );
      console.log(response);
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflow: "auto",
    borderRadius: "5px",
  };

  const deletePlan = async (_id) => {
    try {
      const response = await axios.delete(
        `https://sstcloud.in:8086/api/user/deleteuser/${_id}`
      );
      if (response?.user?.success) {
        toast.success("User removed successfully");
        getAllUsers();
      } else {
        toast.success("User removed successfully");
        getAllUsers();
      }
    } catch (error) {
      console.error("Error deleting plan:", error);
      toast.error("An error occurred while deleting the plan");
    }
  };
  useEffect(() => {
    getAllUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <Typography variant="h6" noWrap>
              <img src={logo} alt="logo" className="dashlogo" />
            </Typography>
            <div className="ms-auto">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  <div className="d-flex justify-content-around align-items-center gap-3">
                    <div>
                      <FontAwesomeIcon
                        icon={faPerson}
                        style={{ fontSize: "22px" }}
                      />
                    </div>
                    <div>{auth?.user?.name}</div>
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <FontAwesomeIcon
                      icon={faSignOut}
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Sign Out
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>Something else here</MenuItem>
                <MenuItem onClick={handleClose}>Separated link</MenuItem> */}
                </Menu>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          open={drawerOpen}
          sx={{
            width: drawerOpen ? 250 : 50,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerOpen ? 250 : 50,
              boxSizing: "border-box",
              transition: "width 0.3s",
              paddingTop: "100px",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            {drawerOpen && !showSideNav2 ? <SideNav /> : <SideNav2 />}
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${drawerOpen ? 250 : 50}px)`,
            transition: "width 0.3s",
          }}
        >
          <Toolbar />
          <Typography variant="p" component="div" sx={{ flexGrow: 1, mb: 3 }}>
            Dashboard / Users
          </Typography>

          <Button variant="contained" color="primary" onClick={handleOpen}>
            Add Users
          </Button>
          <Modal
            open={open}
            onClose={handleCloses}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography id="modal-title" variant="h6" component="h2">
                  Fill User Details
                </Typography>
                <IconButton onClick={handleCloses}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <InputLabel>Post</InputLabel>
                <Select
                  name="post"
                  value={formData.post}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="superadmin">Superadmin</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>

                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button variant="contained" color="primary" type="submit">
                    Add New User
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>

          <Box mt={3}>
            <div className="text-center " style={{ width: "100%" }}>
              <h1 className="repeath1 text-center">All Users</h1>
            </div>

            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Post</th>
                    <th scope="col">Status</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                    {/* <th scope="col">Delete</th> */}
                  </tr>
                </thead>
                <tbody>
                  {users.map((u, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{u.name}</td>
                      <td>{u.email}</td>
                      <td>{u.phone}</td>
                      <td>{u.post}</td>
                      <td>{u.role}</td>
                      <td>
                        <button
                          className="btn btn-outline-success"
                          onClick={() => {
                            navigate(`/dashboard/admin/profile/${u._id}`);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => {
                            deletePlan(u._id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllUsers;
