import React, { useState, useEffect } from "react";
import "./hosting.css";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import lw1 from "../../img/lw1.svg";
import lw2 from "../../img/lw2.svg";
import lw3 from "../../img/lw3.svg";
import lw4 from "../../img/lw4.svg";
import lw5 from "../../img/lw5.svg";
import lw6 from "../../img/lw6.svg";
import Footer from "../../component/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEarth,
  faIndianRupeeSign,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
// import Modal from "../../component/Modal";
import { useNavigate } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const ResellerWindow = () => {
  const [isMobile, setIsMobile] = useState(false);
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/singleplan/Windows-Reseller-Hosting"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://sstcloud.in:8086/api/query/addquery",
        {
          title: "linux-Shared",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data?.success) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
      } else {
        toast.error("Something went wrong. Please try again.");
      }

      navigate("/");
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <ToastContainer />
      {isMobile ? <Header2 /> : <Header />}
      <div className="homehost marginbotm">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-sm-12">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
              >
                Windows Reseller Hosting - Delhi [NCR], India
              </p>
              <h1 style={{ color: "rgb(230,57,63)" }}>Be your own Boss,</h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                Start your own business today.
              </p>
              <ul>
                <li>Private Nameservers</li>
                <li>Overselling Enabled</li>
                <li>Unlimited Domains</li>
                <li>Daily Backup</li>
                <li>Blazing fast performance</li>
                <li>Plesk, ASP.NET</li>
              </ul>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12"></div>

          <div className="col-md-6 col-sm-12 text-center"></div>
        </div>
        <div className="row my-3">
          <div className="col-sm-12">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              RESELLER HOSTING
            </h6>
            <p
              style={{
                fontSize: "16px",

                lineHeight: "24px",
              }}
            >
              Make your Website up with <b>SSTCLOUD</b> best services.{" "}
              <b>SSTCLOUD</b>
              provides you a platform where you can manage and host your
              website. Now, you can Host your website within your budget
              efficiently. We are happy to support you.
            </p>
            <p
              style={{
                fontSize: "16px",

                lineHeight: "24px",
              }}
            >
              <b>SSTCLOUD</b> enables you to start your own web hosting business
              by providing you with affordable and profitable option.{" "}
              <b>SSTCLOUD</b>
              makes managing several accounts by the provision of productive
              tools and 24x7 support. You can host unlimited domain names under
              your brand name and the most essential characteristic is that the
              overselling is enabled.
            </p>
          </div>
        </div>
        <hr />
        <div className="row my-5">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
              id="start"
            >
              RESELLER HOSTING - WINDOWS
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row text-center">
          {plan.map((e) => (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12 p-3">
                <div className="slide">
                  <div>
                    <h4 style={{ color: "rgb(230,57,63)", fontWeight: "700" }}>
                      {e.type}
                    </h4>

                    <p style={{ fontWeight: "600", fontSize: "18px" }}>
                      <FontAwesomeIcon icon={faIndianRupeeSign} /> {e.price} /
                      Month
                    </p>
                    <p>{e.p}</p>
                  </div>
                  <hr
                    style={{
                      width: "100%",
                    }}
                  />
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p1}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p2}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p3}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p4}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p5}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p6}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faEarth} />
                    {e.p7}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p8}
                  </p>
                  <button onClick={() => handleShow(e)} className="querybtn2">
                    Configure Now
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="http://localhost:3000/static/media/logo3.d16e8b77734d74fa9567.png"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6 className="text-light">Linux-Shared-Hosting</h6>
                  <button onClick={handleClose} className="querybtn">
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
                <Form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </Form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ADVANCED FEATURES
            </h1>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b">
            <img
              src={lw1}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Daily backup
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl bb b ">
            <img
              src={lw2}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Private Nameservers
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bb b">
            <img
              src={lw3}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Domain Reseller Panel
            </h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={lw4}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              Overselling Enabled
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col bl b">
            <img
              src={lw5}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              SSD Based Servers
            </h3>
          </div>
          <div className="col-md-4 col-sm-12 p-5 crd3col b">
            <img
              src={lw6}
              alt="img"
              style={{
                height: "70px",
                width: "70px",
              }}
            />
            <h3 className="py-3 maph3" style={{ color: "black" }}>
              cPanel/WHM
            </h3>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row close text-center">
          <div className="col-md-6 col-sm-12 py-5">
            <p>Hosting PHP websites</p>
            <p>for your customers?</p>
            <button
              onClick={() => {
                Navigate("/linux-reseller");
              }}
            >
              Reseller Linux Web Hosting
            </button>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <p>Redefined Cloud Services</p>
            <p>for Enterprises</p>
            <button
              onClick={() => {
                Navigate("/managed-vps-hosting/linux");
              }}
            >
              Managed Linux Cloud Server
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <div className="row linuxbg py-5">
          <div className="col-md-6 col-sm-12">
            <p>Need help? We're always here for you.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <button>Chat with our experts</button>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT LINUX RESELLER WEB HOSTING.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is reseller hosting?</h1>
            <p className="hostp">
              Reseller hosting is a type of web hosting wherein the account
              owner has the ability to use his or her allotted hard drive space
              and bandwidth to host websites on behalf of third parties. The
              reseller purchases the host's services wholesale and then sells
              them to customers, possibly for a profit. A certain portion of
              hard drive space and bandwidth is allocated to the reseller
              account. The reseller may rent a dedicated server from a hosting
              company or resell shared hosting services.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is Windows reseller hosting?</h1>
            <p className="hostp">
              Windows reseller hosting is a type of reseller hosting. You can
              host HTML, PHP, WordPress and ASP.Net websites with Windows
              reseller hosting. You will get a Plesk Panel to manage your sites.
              You can create unlimited Plesk accounts. Also, you can assign a
              particular Plesk Panel to a particular website. We recommend
              Windows reseller hosting to asp.net websites hosting.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is overselling?</h1>
            <p className="hostp">
              Overselling means suppose you purchased a plan of 5 GB SSD
              webspace and you allocate all space to your clients, but all of
              your clients combined are actually using 3GB space and 2 GB space
              is still remaining. In this case, you can resell the space to
              other clients.
              <br />
              Note: Only used space is considered.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is a domain reseller?</h1>
            <p className="hostp"></p>A domain reseller account is a service
            through which you can register/renew/transfer your client’s domain.
            It is a fully white-label service. All domains branded as Registered
            by Your Company Name in Whois.
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What do you mean by private nameservers?</h1>
            <p className="hostp">
              A private name server is a completely white-label solution for
              your network services. Once availed, your clients won’t be able to
              know you are reselling from our company.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide unlimited space and bandwidth?
            </h1>
            <p className="hostp">
              We are not providing Unlimited space and Bandwidth. Unlimited
              space and Bandwidth are marketing concepts. We can give you a
              package as per your requirement/usage.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              What is the difference between shared and reseller hosting?
            </h1>
            <p className="hostp">
              In shared hosting, you will get one cPanel/WHM through which you
              have to manage your websites. In reseller hosting, you can create
              an unlimited cPanel/WHM and manage your sites. You can share
              particular cPanel/WHM to your clients/developers to design manage
              a website.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible for me to create packages as per my client’s needs?
            </h1>
            <p className="hostp">
              Yes, It is definitely possible for you to create a package as per
              your client’s needs. Just reach out to our experts and they will
              provide the best solution that fits your requirements.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResellerWindow;
