import React, { useState, useEffect } from "react";
import "./hosting.css";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import {
  faCheck,
  faEarth,
  faIndianRupeeSign,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";
// import Modal from "../../component/Modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Form } from "react-bootstrap";

const WindowsHostingShared = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };
  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/singleplan/Windows-Hosting"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);
  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://sstcloud.in:8086/api/query/addquery",
        {
          title: "Windows-Shared",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data?.success) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
      } else {
        toast.error("Something went wrong. Please try again.");
      }

      navigate("/");
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    // Initial check on mount
    handleResize();

    // Add event listener to check on resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <ToastContainer />
      <div className="homehost marginbotm">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-sm-12">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
              >
                Windows Shared Hosting - Delhi [NCR], India
              </p>
              <h1 style={{ color: "rgb(230,57,63)" }}>
                Host Unlimited Domains
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                Seamlessly with our blazing-fast SSD based servers
              </p>
              <ul>
                <li>
                  Fully featured plans with cPanel Control Panel, PHP, MySQL,
                  Plesk, ASP.NET
                </li>
                <li>99.9% uptime guarantee</li>
                <li>Free Migration & Daily Backup</li>
                <li>Multilingual 24 x 7 Support</li>
                <li>30 Days money back guarantee</li>
              </ul>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-3">
          <div className="col-sm-12">
            <h6
              style={{
                fontSize: "14px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              WHAT IS SHARED HOSTING?
            </h6>
            <p
              style={{
                fontSize: "16px",

                lineHeight: "24px",
              }}
            >
              Make your Website up with <b>SSTCLOUD</b> best services.{" "}
              <b>SSTCLOUD</b>
              provides you a platform where you can manage and host your
              website. Now, you can Host your website within your budget
              efficiently. We are happy to support you.
            </p>
            <p
              style={{
                fontSize: "16px",

                lineHeight: "24px",
              }}
            >
              Shared web hosting is the quickest way to get your website online.
              Be it plain HTML, PHP or ASP.NET we support all web languages. You
              can select Linux Hosting to host HTML/PHP based websites or
              Windows hosting for HTML, PHP or ASP.NET websites. We also offer
              multiple locations for Linux Hosting so that your website is
              closest your visitors' location guaranteeing fastest loading
              speeds. Our Shared Hosting comes with 99.9% or higher guaranteed
              uptime, 24x7x365 Super-Fast Support and a 30 days no conditions
              money back guarantee.
            </p>
          </div>
        </div>
        <hr />
        <div className="row my-5">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              SHARED HOSTING - Windows
            </h1>
          </div>
        </div>
      </div>
      <div id="start" className="container-fluid">
        <div className="row text-center">
          {plan.map((e) => (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12 p-3">
                <div className="slide">
                  <div>
                    <h4 style={{ color: "rgb(230,57,63)", fontWeight: "700" }}>
                      {e.type}
                    </h4>

                    <p style={{ fontWeight: "600", fontSize: "18px" }}>
                      <FontAwesomeIcon icon={faIndianRupeeSign} /> {e.price}{" "}
                      /Month
                    </p>
                    {/* <p>{e.p}</p> */}
                  </div>
                  <hr
                    style={{
                      width: "100%",
                    }}
                  />
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p1}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p2}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p3}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p4}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p5}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p6}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faEarth} />
                    {e.p7}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} />
                    {e.p8}
                  </p>
                  <button onClick={() => handleShow(e)} className="querybtn2">
                    Configure Now
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="http://localhost:3000/static/media/logo3.d16e8b77734d74fa9567.png"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6 className="text-light">Linux-Shared-Hosting</h6>
                  <button onClick={handleClose} className="querybtn">
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
                <form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      <div className="container my-5">
        <div className="row close text-center">
          <div className="col-md-6 col-sm-12 py-5">
            <p>Would you like to host an</p>
            <p>ASP.NET website instead ?</p>
            <button
              onClick={() => {
                navigate("/linux-hosting");
              }}
            >
              Shared Linux Web Hosting
            </button>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <p>Solutions for</p>
            <p>High Traffic websites</p>
            <button
              onClick={() => {
                navigate("/managed-vps-hosting/linux");
              }}
            >
              Managed Linux Cloud Serverss
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <div className="row linuxbg py-5">
          <div className="col-md-6 col-sm-12">
            <p>Need help? We're always here for you.</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <button>Chat with our experts</button>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "35px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              FREQUENTLY ASKED QUESTIONS ABOUT WINDOWS SHARED WEB HOSTING.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is shared hosting?</h1>
            <p className="hostp">
              A shared web hosting service refers to a web hosting service where
              many websites reside on one web server connected to the Internet.
              Each site "sits" on its own partition, or section/place on the
              server, to keep it separate from other sites. As the name
              suggests, you will be sharing the bandwidth, Internet connection
              and memory of the host machine with other customers.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is Linux shared hosting?</h1>
            <p className="hostp">
              A Linux shared hosting is a hosting service suitable to host
              websites that are designed from various computer
              languages/applications developed in PHP such as
              Joomla/Wordpress/Drupal, plain HTML, etc. Linux does not support
              ASP.NET websites. For ASP.NET sites you would have to take our
              Windows shared hosting.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to host multiple websites with Linux shared
              hosting?
            </h1>
            <p className="hostp">
              Yes, you can host multiple sites on Linux shared hosting. You
              would have to choose Linux shared hosting business-2 or higher
              plans.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              How many email accounts and databases are you providing with Linux
              shared hosting package?
            </h1>
            <p className="hostp">
              We provide unlimited databases and email accounts with Linux
              shared hosting.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is the Bandwidth based on monthly consumption or yearly
              consumption?
            </h1>
            <p className="hostp">
              The Bandwidth is based on Monthly consumption.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible for me to design a website through Linux shared
              hosting package?
            </h1>
            <p className="hostp">
              Yes, we have already installed Application Installer [
              Installatron ] on our shared node. So you can design your website
              using Wordpress/Joomla/Drupal etc.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Is it possible to purchase additional space along with shared
              hosting account?s
            </h1>
            <p className="hostp">No, you need to upgrade to higher plan.</p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Which hosting plan supports PHP sites?</h1>
            <p className="hostp">
              You can choose our Linux shared hosting which supports PHP Sites.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Is Tax included in the cost or not?</h1>
            <p className="hostp">
              The pricing you see on the website is excluding Tax. As per
              government norms, 18% GST would be applicable to the cost you see
              on the website.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What is SSD?</h1>
            <p className="hostp">
              SSD(Solid State Drive) is a type of hard drive. SSD is much faster
              and more reliable than normal SATA hard drives, as it is based on
              Flash storage and does not have any moving parts.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              If I have purchased the domain from some other vendor and I am
              looking to host a website with you, is it possible?
            </h1>
            <p className="hostp">
              Yes, it is definitely possible to host your website with us even
              though you have purchased your domain from another vendor. You can
              also move your domain to us. Kindly get in touch with our experts
              and they will assist you with the process.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">How many emails can I send?</h1>
            <p className="hostp">You can send 100 emails/hour/domain.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">What kind of support do you provide?</h1>
            <p className="hostp">
              We are providing True 24x7 Support via Phone, Live chat and
              helpdesk support.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Is your server DDOS protected?</h1>
            <p className="hostp">
              Yes, we only provide DDOS protected servers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide free SSL with Linux shared hosting?
            </h1>
            <p className="hostp">
              Yes, we do provide LetEncrypt SSL with Linux shared hosting.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">Which hosting supports to asp.net site?</h1>
            <p className="hostp">
              Our Windows shared hosting would be the best option to host an
              Asp.net site.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 py-5">
            <h1 className="hosth1">
              Do you provide dedicated IP with Linux shared hosting?
            </h1>
            <p className="hostp">
              Yes, we provide a dedicated IP with shared hosting. You can also
              order dedicated IPs here :- <NavLink>Click Here</NavLink>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WindowsHostingShared;
