import React, { useState, useEffect } from "react";
import "./ssl.css";
import Header2 from "../../component/Header2";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faIndianRupeeSign,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../component/Modal";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Backup = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://sstcloud.in:8086/api/plan/singleplan/Backup"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    // Initial check on mount
    handleResize();

    // Add event listener to check on resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const card = [
    {
      img: "./img/backup1.jpeg",
      head: "Daily Backups with Modern Storage Infrastructure",
      p: "Sstcloud Cloud ensures daily backups using state-of-the-art storage technology, guaranteeing the safety and accessibility of your data. With our modern infrastructure, you can trust that your data is secure and protected.",
    },
    {
      img: "./img/backup2.jpeg",
      head: "Configurable Backup Windows",
      p: "Tailor your backup schedules to align with your operations using Sstcloud Cloud configurable backup windows. This flexibility minimizes disruptions while ensuring that your data remains protected and accessible whenever you need it.",
    },
    {
      img: "./img/backup3.jpeg",
      head: "Instant and Recurring Snapshots",
      p: "Capture instant snapshots of your data for immediate backup, providing an added layer of security. Set up recurring snapshots to ensure regular backups, giving you peace of mind knowing your data is always protected against potential loss.",
    },
  ];
  const details = [
    {
      head: "SS50",
      name: "Hyper Converged High Speed Storage Backup",
      desc: "Experience world class hyper converged storage solution with unlimited IOPS, super fast performing storage backup.",
    },
    {
      head: "SS100",
      name: "Hyper Converged High Speed Storage Backup",
      desc: "Experience world class hyper converged storage solution with unlimited IOPS, super fast performing storage backup.",
    },
    {
      head: "SS500",
      name: "Hyper Converged High Speed Storage Backup",
      desc: "Experience world class hyper converged storage solution with unlimited IOPS, super fast performing storage backup.",
    },
  ];
  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      <div className="home2 marginbotm ">
        <div className="container-fluid p-5 ">
          <div className="row">
            <div className="col-md-6 col-sm-12"></div>
            <div className="col-md-6 col-sm-12 text-black">
              <h1
                style={{
                  color: "rgb(230,57,63)",
                  fontWeight: "600",
                  fontSize: "41px",
                  lineHeight: "46px",
                  textAlign: "left",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Daily, incremental backups and instant recovery with snapshots
              </h1>
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                Designed for the always-on distributed computing world, the
                parallel multi-controller, shared-nothing system with no Single
                Points of Failure (SPoF) provides a measured 99.999% uptime to
                customers. From instant crash-consistent snapshots to three
                synchronous copies to a unique erasure coding implementation,
                Sstcloud Storage has the architecture, the support system, and
                the features to provide you with unmatched reliability.
              </p>

              <button
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                style={{
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-3">
          <div className="col-sm-12">
            <h6
              style={{
                fontSize: "20px",
                fontWeight: "900",
                lineHeight: "20px",
              }}
            >
              Get started with Sstcloud Cloud backups and snapshots
            </h6>
            <Button
              variant="contained"
              style={{ backgroundColor: "rgb(230,57,63)", margin: "10px" }}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              <FontAwesomeIcon icon={faMessage} /> Contact Us
            </Button>
          </div>
          <hr />
        </div>
      </div>
      <div className="container text-center">
        <div id="start" className="row my-5">
          <div className="col-sm-12 text-center">
            <h1
              style={{
                fontSize: "32px",
                lineHeight: "36px",
                fontWeight: "700",
                color: "rgb(230,57,63)",
              }}
            >
              Choose the Right Hosting Plan
            </h1>
          </div>
        </div>
        <div className="row">
          {plan.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12 text-center">
                <div className="manageddiv" style={{ height: "520px" }}>
                  <h5 className="managedh">{e.type}</h5>
                  <p style={{ fontSize: "20px", fontWeight: "600" }}>
                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                    {e.price} /month
                  </p>
                  <Modal name="Configure Now" />
                  <p className="managedmp mt-4">{e.p1}</p>
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p2}
                  </p>
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p3}
                  </p>
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p4}
                  </p>
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p5}
                  </p>
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p6}
                  </p>
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p7}
                  </p>
                  <p className="managedmp mt-4">
                    <FontAwesomeIcon icon={faCheck} /> {e.p8}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container-fluid my-5">
        <div className="row my-5">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(230,57,63)",
              }}
            >
              Key Features
            </p>
          </div>
        </div>
        <div className="row text-center">
          {card.map((e, index) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 p-3">
                <div key={index}>
                  <img
                    style={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                    src={e.img}
                    alt="img"
                  />
                  <h3
                    style={{
                      fontSize: "24px",
                      lineHeight: "24px",
                      margin: "10px 10px",
                      color: "rgb(230,57,63)",
                      textAlign: "left",
                    }}
                  >
                    {e.head}
                  </h3>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#666666",
                      textAlign: "justify",
                      margin: "10px 10px",
                    }}
                  >
                    {e.p}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="row text-center">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-8 col-sm-12 px-5">
            <h1 style={{ color: "rgb(230,57,63)" }}>
              Pay-as-you-go Storage for Snapshots, Images and Backups
            </h1>
            <p>
              Our infrastructure is strategically located in the center of
              Sofia, giving our customers an excellent location within the
              European Union and close to the intersection
            </p>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
      </div>
      <div className="container-fluid p-3">
        <div className="row none">
          <div className="col-md-4 col-sm-12">
            <h2>Name</h2>
          </div>
          <div className="col-md-8 col-sm-12">
            <h2>Description</h2>
          </div>
        </div>
        {details.map((e) => (
          <>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <p style={{ fontSize: "18px", lineHeight: "18px" }}>{e.head}</p>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "16px",
                    color: "gray",
                  }}
                >
                  {e.name}
                </p>
              </div>
              <div
                className="col-md-8 col-sm-12"
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: "600",
                  }}
                >
                  {e.desc}
                </p>
              </div>
            </div>
          </>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default Backup;
