import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Header2 from "../component/Header2";
import "./Gpu.css";
import Footer from "../component/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,

  //   faRupee,
  //   faRupeeSign,
} from "@fortawesome/free-solid-svg-icons";

const GPU = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    // Initial check on mount
    handleResize();

    // Add event listener to check on resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const card = [
    {
      img: "./img/gpu6.png",
      head: "AI (Artificial Intelligence)",
      para: "GPU servers are used in AI to provide more computing power and speed for deep learning and other intensive tasks. Companies often use GPU cloud computing to support and scale their AI workflows.",
    },
    {
      img: "./img/gpu7.png",
      head: "Machine Learning",
      para: "sstcloud's GPU Cloud hosting is ideal for Big Data and machine learning applications, with the ability to quickly solve challenging computational problems with parallel-running queries and algorithms.",
    },
    {
      img: "./img/gpu8.png",
      head: "Automobiles",
      para: "GPU servers are used in the automobile industry for CAD and simulation, autonomous driving, virtual testing and validation, and image and video processing, helping to reduce costs and improve efficiency, accuracy, and product development.",
    },
    {
      img: "./img/gpu9.png",
      head: "Healthcare sector",
      para: "GPU servers are used in the healthcare sector to process medical imaging data, analyze genetic and patient data, and support telemedicine applications, improving efficiency, reducing costs, and providing better care to patients.",
    },
    {
      img: "./img/gpu10.png",
      head: "Virtual infrastructure-based applications",
      para: "GPU Servers provide improved performance, scalability, and security for VDI (Virtual Desktop Interface) applications, such as video editing and 3D modeling.",
    },
  ];
  const card2 = [
    {
      img: "./img/gpu1.png",
      text: "Hi-Performance GPU",
    },
    {
      img: "./img/gpu2.svg",
      text: "Instant Account Setup",
    },
    {
      img: "./img/gpu3.png",
      text: "Zero Setup Fees",
    },
    {
      img: "./img/gpu4.svg",
      text: "SSD based servers",
    },
    {
      img: "./img/gpu5.svg",
      text: "24/7 Support",
    },
  ];

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}

      <div className="gpubanner marginbotm">
        <div className="container-fluid gpy1">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <p className="p1">GPU Cloud Server- Delhi [NCR], India</p>
              <h1 style={{ color: "rgb(230,57,63)" }}>
                Unlock the power of the GPU today with our GPU Cloud servers!
              </h1>
              <p className="p2">
                Looking for a powerful, energy-efficient, and dedicated
                high-configuration GPU server? Look no further than our
                NVIDIA-powered GPU Cloud servers! Perfect for scientific and
                advanced computing, AI, ML, and DL applications, these servers
                provide unbeatable performance and reliability. Get your project
                up and running quickly with our simple
              </p>
              <ul>
                <p className="p3">Our GPU Plan comes with-</p>
                <li>NVIDIA's A5000 - Powered GPU Servers</li>
                <li>State-of-the-art Tier IV India-Based Data Center</li>
                <li>Top Performance with AMD EPYC Plans</li>
                <li>Ultra-Fast SSD DiskSpace, Upto 1 Gbps Network Speed</li>
              </ul>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                onClick={() => {
                  const hostingDiv = document.getElementById("start");
                  if (hostingDiv) {
                    hostingDiv.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                View Pricing
              </button>
            </div>
            <div className="col-md-4 col-sm-12"></div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-evenly flex-wrap my-5">
        {card2.map((e) => (
          <>
            <div className="text-center">
              <img
                alt="er"
                src={e.img}
                style={{ height: "50px", width: "50px" }}
              />
              <h6>{e.text}</h6>
            </div>
          </>
        ))}
      </div>

      <p className="gpup">
        GPUs are now becoming more and more important in the modern era.
        Conventional CPUs runs across a series of tasks and involves a lot of
        interactivities, GPU on the other hand breaks complex problems into
        millions of separate tasks and work on all of them altogether. Compared
        to the GPU, which can do thousands of processes simultaneously, the CPU
        can only manage a small number of them. The combination of parallel
        processing, faster performance, cost-effectiveness, and energy
        efficiency makes GPUs a more practical choice for many businesses,
        particularly for those in fields such as finance, scientific research,
        and AI.
      </p>

      <div className="container-fluid text-center">
        <div className="row  d-flex justify-content-center">
          {card.map((e, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6 col-sm-12 mapgpu mx-5 d-flex"
            >
              <div className="btn btn1 py-5">
                <img alt="img" src={e.img} />
                <div>
                  <h3>{e.head}</h3>
                  <p>{e.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              GPU PLAN FEATURES
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="my-5 py-5">
              <p className="gpufeature">AMD EPYC Server</p>
              <p className="gpufeature2">
                Our GPU Server uses AMD EPYC Plans. AMD EPYC servers provide a
                powerful and versatile computing platform for enterprise-level
                applications, with high performance, scalability, and advanced
                security features.
              </p>
            </div>
            <div className="my-5 py-5">
              <p className="gpufeature">Full Control</p>
              <p className="gpufeature2">
                Get full root administrative access to your GPU servers! Change
                the programme settings, add or remove applications, control the
                accessible ports, and so much more.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <img alt="img" src="./img/gpu11.svg" className="featureimg" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="my-5 py-5">
              <p className="gpufeature">99.95% Uptime SLA</p>
              <p className="gpufeature2">
                Due to the highly redundant infrastructure, increased network
                connectivity, and enterprise-grade hardware, our GPU servers
                provide the best uptime of 99.95%. Our Tier-4 and ISO-certified
                data centres are highly secure, furnished and well-equipped with
                top-tier hardware.
              </p>
            </div>
            <div className="my-5 py-5">
              <p className="gpufeature">
                Both Linux and Windows operating systems!
              </p>
              <p className="gpufeature2">
                Both Linux and Windows operating systems are available for your
                use. Simply pick your preferred operating system, and it will be
                installed for you!
              </p>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-8 col-sm-12">
            <p className="gpufeature">Up to 1 Gbps Network Speed</p>
            <p className="gpufeature2">
              We make an effort to give you the best!
            </p>
            <p className="gpufeature2">
              Our cutting-edge network is configured for lightning-fast loading
              times. You can host websites and apps with no performance problems
              with a data transfer speed of 1Gbps. Visitors can even enjoy some
              excellent surfing there.
            </p>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
      </div>
      <div className="featbotom">
        <p>
          Looking for a different GPU? Please fill out this <span>form</span>
        </p>
      </div>

      <div className="container-fluid p-5">
        <div className="row my-5">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              GPU COMPARISONS WITH COMPETITORS
            </p>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table>
            <tr>
              <th
                style={{ backgroundColor: "rgb(230, 57, 63)", color: "white" }}
              >
                GPU Name
              </th>
              <th
                style={{ backgroundColor: "rgb(230, 57, 63)", color: "white" }}
              >
                NVIDIA A5000 Offered by sstcloud
              </th>
              <th
                style={{ backgroundColor: "rgb(230, 57, 63)", color: "white" }}
              >
                NVIDIA P4 Offered by Google
              </th>
              <th
                style={{ backgroundColor: "rgb(230, 57, 63)", color: "white" }}
              >
                NVIDIA T4 Offered by Google
              </th>
              <th
                style={{ backgroundColor: "rgb(230, 57, 63)", color: "white" }}
              >
                NVIDIA T4 Offered by AWS
              </th>
              <th
                style={{ backgroundColor: "rgb(230, 57, 63)", color: "white" }}
              >
                NVIDIA A10G Offered by AWS
              </th>
              <th
                style={{ backgroundColor: "rgb(230, 57, 63)", color: "white" }}
              >
                RTX6000 Offered by Linode
              </th>
            </tr>
            <tr>
              <th>GPU Dedicated RAM</th>
              <td>24GB GDDR6</td>
              <td>8GB GDDR5</td>
              <td>16 GB GDDR6</td>
              <td>16 GB GDDR6</td>
              <td>24GB GDDR6</td>
              <td>24 GB GDDR6</td>
            </tr>
            <tr>
              <th>Shading Units</th>
              <td>8192</td>
              <td>2560</td>
              <td>2560</td>
              <td>2560</td>
              <td>9216 </td>
              <td>4608</td>
            </tr>
            <tr>
              <th>Base Clock Speed</th>
              <td>1170 MHz</td>
              <td>886 MHz</td>
              <td>585 MHz</td>
              <td>585 MHz</td>
              <td>1320 MHz </td>
              <td>1440 MHz</td>
            </tr>
            <tr>
              <th>Tensor vCPUs</th>
              <td>256</td>
              <td>-</td>
              <td>320</td>
              <td>320</td>
              <td>288 </td>
              <td>576</td>
            </tr>
            <tr>
              <th>ROPS</th>
              <td>96</td>
              <td>64</td>
              <td>64</td>
              <td>64</td>
              <td>96 </td>
              <td>96</td>
            </tr>
            <tr>
              <th>vCPU</th>
              <td>8</td>
              <td>At an additional price</td>
              <td>At an additional price</td>
              <td>8</td>
              <td>8 </td>
              <td>8</td>
            </tr>
            <tr>
              <th>Server RAM (Dedicated RAM)</th>
              <td>32 GB</td>
              <td>At an additional price</td>
              <td>At an additional price</td>
              <td>32 GB</td>
              <td>32 GB </td>
              <td>32 GB</td>
            </tr>
            <tr>
              <th>DiskSpace</th>
              <td>800 GB SSD</td>
              <td>At an additional price</td>
              <td>At an additional price</td>
              <td>225GB NVMe SSD</td>
              <td>450 GB SSD</td>
              <td>640 GB</td>
            </tr>
            <tr>
              <th>Starts from -</th>
              <th>$300.00 USD/Month Or Rs.20000/Month</th>
              <th>$306.00 USD/Month + vCPU and DiskSpace cost</th>
              <th>$178.00 USD/Month + vCPU and DiskSpace cost</th>
              <th>$548.00 USD/Month</th>
              <th>$884.76 USD/Month</th>
              <th>$1000.00 USD/Month</th>
            </tr>
          </table>
        </div>
      </div>

      <div className="container my-5">
        <div className="row my-5">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              ALL PLANS INCLUDE
            </p>
          </div>
        </div>
        <div className="row tick">
          <div className="col-md-4 col-sm-12 ">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Root/Administrative Accesss
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Easy Scaling
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Robust & Consistent Performance
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              IPv4/IPv6
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              No Setup Fee
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Unbeatable Pricing
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              SSD DiskSpace
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              Secure & Reliable Service
            </p>
            <p className="d-flex">
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: "green", padding: "0 20px" }}
                />{" "}
              </div>
              24/7 Support via Live Chat, Phone and helpdesk
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              FREQUENTLY ASKED QUESTIONS
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div class="accordion" id="accordionPanelsStayOpenExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <strong> What do you mean by a GPU server?</strong>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  class="accordion-collapse collapse"
                >
                  <div class="accordion-body">
                    <p>
                      A GPU server is basically a type of computing server that
                      has a GPU card that provides fast, flexible computing and
                      a highly stable environment. It’s basically used in
                      different scenarios like Artificial Intelligence, Machine
                      Learning, deep learning, decoding, scientific computing,
                      etc.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    <strong>
                      {" "}
                      What is the main difference between GPU and CPU?
                    </strong>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  class="accordion-collapse collapse"
                >
                  <div class="accordion-body">
                    <p>
                      In the world of supercomputers, GPU is really popular, yet
                      it utilizes the conventional concept of parallel
                      processing. The GPU breaks big tasks into small tasks and
                      functions on them in one go, however, unlike the CPU, that
                      appears to work on a sequence of tasks. CPU can handle
                      fewer tasks at a time, while GPU can handle several
                      concurrent tasks at a time. Thus, GPUs offer a higher
                      level of latency than CPUs. It's the best option to host
                      resource-intensive applications.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <strong>How GPU helps the CPU?</strong>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  class="accordion-collapse collapse"
                >
                  <div class="accordion-body">
                    <p>
                      CPUs are not built to run lots of tasks at the same time.
                      They focus on one task at a time and then assign the task
                      in sequence on a priority basis. It runs several tasks
                      simultaneously and will lag the CPU, and the performance
                      will be low. By adding a GPU server, you can distribute
                      the demanding tasks to the GPU, and the CPU resources will
                      remain free. The combination effectively reduces the CPU
                      workload, and the GPU can escalate the computing task much
                      faster.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFour"
                  >
                    <strong> What are Tensor vCPUs?</strong>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFour"
                  class="accordion-collapse collapse"
                >
                  <div class="accordion-body">
                    <p>
                      NVIDIA Tensor vCPUs are specialized processing units
                      available in NVIDIA GPUs. Tensor vCPUs accelerate matrix
                      operations, which are the heart of AI workloads.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    <strong> What Is GPU Computing?</strong>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseFive"
                  class="accordion-collapse collapse"
                >
                  <div class="accordion-body">
                    <p>
                      It is general-purpose computing with the help of a
                      Graphics Processing Unit (GPU). These calculations are
                      done faster by using a GPU and a Central Processing Unit
                      (CPU) together to speed them up. This is done for
                      applications that are usually done by the CPU alone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default GPU;
